import { useContext, createContext, useState, useEffect } from "react";

import "./App.css";
import { Route, Routes, Navigate, BrowserRouter } from "react-router-dom";
import ScrollToTop from "./utilities/ScrollToTop";
import RouteWrapper from "authsignin/RouteWrapper";

import { Amplify, Auth, Hub } from "aws-amplify";
import awsExports from "./aws-exports";
import "@aws-amplify/ui-react/styles.css";
import { StyledEngineProvider } from "@mui/material/styles";
import { usePostHog } from "posthog-js/react";
import TypesenseInstantSearchAdapter from "typesense-instantsearch-adapter";
import { Configure, InstantSearch } from "react-instantsearch";
import { datadogLogs } from "@datadog/browser-logs";
import { createShopSpecificRouting, getDefaultFilters } from "useSearchConfig";

import lilProductClient from "./axios/lilProductClient";
import endpoints from "./axios/endpoints.js";
import vendors from "./constants/vendors";
import { fetchUserSuccess } from "features/userSlice";
import { useDispatch } from "react-redux";
import { MUI_THEME } from "custom/muiStylingOverrides";
import { ThemeProvider } from "@mui/material/styles";
import MaintenancePage from "MaintenacePage";

Amplify.configure({
  Auth: {
    region: awsExports.REGION,
    userPoolId: awsExports.USER_POOL_ID,
    userPoolWebClientId: awsExports.USER_POOL_APP_CLIENT_ID,
  },
});

// Force USER_PASSWORD_AUTH if needed
Auth.configure({
  ...awsExports,
  authenticationFlowType: "USER_PASSWORD_AUTH",
});

// Datadog configuration
// Client token is safe to show
datadogLogs.init({
  clientToken: "pub6d3a02b0106da10c398d28f1af890f8b",
  site: "datadoghq.com",
  forwardErrorsToLogs: true,
  forwardConsoleLogs: "all",
  sessionSampleRate: 100,
});

// Typesense client configuration. At the app level so that
// the search bar can live in the header, and the refinements
// + hits can live elsewhere
export const typesenseInstantsearchAdapter = new TypesenseInstantSearchAdapter({
  server: {
    apiKey: process.env.REACT_APP_TYPESENSE_SEARCH_API_KEY2, // API key that only allows search
    nodes: [
      {
        host: process.env.REACT_APP_TYPESENSE_HOST,
        port: "443",
        protocol: "https",
      },
    ],
    cacheSearchResultsForSeconds: 2 * 60, // Cache search results from server. Defaults to 2 minutes. Set to 0 to disable caching.
  },
  // The following parameters are directly passed to Typesense's search API endpoint.
  //  So you can pass any parameters supported by the search endpoint below.
  //  query_by is required.
  additionalSearchParameters: {
    preset: "search_products_2",
  },
});

export const typesenseProductsIndexName =
  process.env?.REACT_APP_IS_STAGING === "true"
    ? "products_staging"
    : "products_live";
export const searchClient = typesenseInstantsearchAdapter.searchClient;
export const typesenseClient = typesenseInstantsearchAdapter.typesenseClient;

const UserStateContext = createContext();
const useUserStateContext = () => useContext(UserStateContext);
export { UserStateContext, useUserStateContext };

const DataContext = createContext();
const useDataContext = () => useContext(DataContext);
export { DataContext, useDataContext };

const CheckoutContext = createContext();
const useCheckoutContext = () => useContext(CheckoutContext);
export { CheckoutContext, useCheckoutContext };

function App() {
  const [connectedVendorCodes, setConnectedVendorCodes] = useState({
    ab_data: null,
    cm_data: null,
    sm_data: null,
    ss_data: null,
    as_data: null,
    acc_data: null,
    pg_data: null,
    asc_data: null,
    sta_data: null,
    slc_data: null,
  });
  // TODO for isAuthed: define 4 states --> true, false, null (deciding), waiting (waiting for sign in event after confirm sign up)
  const [isUserAuthed, setIsUserAuthed] = useState(null);
  const [userCreds, setUserCreds] = useState({
    shopName: null,
    cognitoID: null,
    checkoutEnabled: null,
  });
  const [checkoutFormData, setCheckoutFormData] = useState({
    shippingAddress: null,
    warehouseShippingMethods: [],
    paymentMethod: null,
    poNumber: "",
  });
  const [canConfirmOrder, setCanConfirmOrder] = useState(false);
  const [lastRoutePath, setLastRoutePath] = useState(null);
  const [hasUserZip, setHasUserZip] = useState(null);

  // The goal of searchSession is to test whether our search sessions are helping users
  // 1. find the product they want
  // 2. update their cart accordingly
  // Our metric for a successful search session is whether a search converts to an add_to_cart event.
  // A session starts when a user searches via the searchbar or facet subheader.
  // A session ends when a user starts a new search or navigates to another page
  // UNLESS they are continuing their search by clicking into a product page from the shopping page.
  // Events were historically captured on user-driven actions (search or page nav), meaning sessions that lead to idle time were considered invalid.
  // PosthogSessionTracker should flush events if recording has stopped.
  // Also note that user experiences starting from the default shopping page are not valid search sessions.
  const [searchSession, setSearchSession] = useState({
    in_progress: false,
    search_term: null,
    items_added: [],
    updated_cart: false,
    prev_path: null,
    added_direct_vendor_items: false,
  });

  const posthog = usePostHog();
  const dispatch = useDispatch();

  // Initialize Intercom
  window.Intercom("boot", {
    api_base: "https://api-iam.intercom.io",
    app_id: "iwnti10l",
  });

  // Check if user can confirm order
  useEffect(() => { 
    const hasShippingAddr = checkoutFormData["shippingAddress"];
    const hasPaymentMethod = checkoutFormData["paymentMethod"];
    const hasShippingMethods =
      checkoutFormData["warehouseShippingMethods"].length > 0;

    if (hasShippingAddr && hasPaymentMethod && hasShippingMethods) {
      setCanConfirmOrder(true);
    } else {
      setCanConfirmOrder(false);
    }
  }, [checkoutFormData]);

  // On app load, check if there is a valid user session
  useEffect(() => {
    const checkAuth = async () => {
      try {
        const currentUser = await Auth.currentAuthenticatedUser();
        const userShopName = currentUser.attributes?.["custom:shop_name"];
        let userID = currentUser?.username || "";
        // If userID has an @, switch to sub
        if (userID.includes("@")) {
          userID = currentUser?.attributes?.sub;
        }
        setIsUserAuthed(true);
        setUserCreds({
          shopName: userShopName,
          cognitoID: userID,
          checkoutEnabled: currentUser?.checkout_enabled,
        });

        // Identify user in PostHog
        posthog?.identify(userID, {
          email: currentUser.attributes?.["email"],
          shopName: userShopName,
        });
      } catch (err) {
        console.log("No valid Cognito session: ", err?.message);
        setIsUserAuthed(false);
        setUserCreds({
          shopName: null,
          cognitoID: null,
          checkoutEnabled: null,
        });
      }
    };

    // Check auth on component mount
    checkAuth();

    // Listen for auth events
    const hubListener = Hub.listen('auth', ({ payload: { event } }) => {
      switch (event) {
        case 'signIn':
          checkAuth();
          break;
        default:
          break;
      }
    });

    // Cleanup listener
    return () => hubListener();
  }, [posthog]);

  // If userCreds are valid, fetch user data
  const getUserData = async () => {
    try {
      const headers = {
        "User-Identifier": userCreds.cognitoID,
      };
      const userResponse = await lilProductClient.get(
        endpoints.users(userCreds.cognitoID),
        { headers },
      );
      const distrData = userResponse.data.Distributors || {};
      const userZipExists = Object.prototype.hasOwnProperty.call(
        userResponse.data,
        "zip",
      );
      setHasUserZip(userZipExists);

      // Build connected vendor codes
      let newConnectedCodes = {};
      const distrCodes = Object.keys(distrData);
      const vendorEntries = Object.entries(vendors);

      for (const [dataKey, vendorDataFields] of vendorEntries) {
        const vendorCode = vendorDataFields.code;
        if (distrCodes.includes(vendorCode)) {
          newConnectedCodes[dataKey] = vendorCode;
        } else {
          newConnectedCodes[dataKey] = false;
        }
      }
      setConnectedVendorCodes(newConnectedCodes);
      dispatch(fetchUserSuccess({ userObj: userResponse.data }));

      // PostHog: Mark connected
      const hasAtLeastOneConnection = Object.values(newConnectedCodes).some(
        (status) => status,
      );
      posthog?.people?.set({ connectedVendors: hasAtLeastOneConnection });

      // Intercom
      window.Intercom("update", {
        api_base: "https://api-iam.intercom.io",
        app_id: "iwnti10l",
        name: userResponse.data?.firstName + " " + userResponse.data?.lastName,
        email: userResponse.data?.email,
        user_id: userResponse.data?.UserId,
        shop_name: userResponse.data?.["custom:shop_name"],
        buying_group: userResponse.data?.buyingGroupName,
        created_at: userResponse.data?.createdAt,
      });
    } catch (error) {
      console.error("Failed to get user data:", error?.message);
    }
  };

  useEffect(() => {
    // Only fetch user data if we have a valid cognitoID
    if (userCreds.cognitoID) {
      getUserData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userCreds.cognitoID]);

  // Data context: top-level app state
  const dataContext = {
    setConnectedVendorCodes,
    connectedVendorCodes,
    searchSession,
    setSearchSession,
  };

  // User state context: sign out & user info
  const userStateContext = {
    async signOut() {
      try {
        // Actually sign out from AWS Cognito
        await Auth.signOut();

        // Reset PostHog
        posthog?.reset();

        // Shutdown Intercom session
        if (window.Intercom) {
          window.Intercom("shutdown");
        }
      } catch (error) {
        console.error("Error during signOut:", error);
      }
      // We'll do the final redirect & reload in ProfileMenu.js
    },
    setUserCreds,
    userCreds,
    setIsUserAuthed,
    isUserAuthed,
    hasUserZip,
    setHasUserZip,
  };

  // Checkout context
  const checkoutContext = {
    checkoutFormData,
    setCheckoutFormData,
    canConfirmOrder,
    setCanConfirmOrder,
    lastRoutePath,
    setLastRoutePath,
  };

  const defaultFilters = getDefaultFilters(connectedVendorCodes);
  const customRouting = createShopSpecificRouting();

  return (
    <UserStateContext.Provider value={userStateContext}>
      <DataContext.Provider value={dataContext}>
        <CheckoutContext.Provider value={checkoutContext}>
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={MUI_THEME}>
              <InstantSearch
                searchClient={searchClient}
                indexName={typesenseProductsIndexName}
                routing={customRouting}
                future={{
                  preserveSharedStateOnUnmount: true,
                }}
              >
                <Configure filters={defaultFilters} />
                <BrowserRouter>
                  <ScrollToTop />
                  <Routes>
                    <Route
                      path="/terms"
                      element={
                        <RouteWrapper isAuthed={false} component="terms" />
                      }
                    />
                    <Route
                      path="/privacy"
                      element={
                        <RouteWrapper isAuthed={false} component="privacy" />
                      }
                    />
                    <Route
                      path="/product-demo"
                      element={
                        <RouteWrapper
                          isAuthed={false}
                          component="product-demo"
                        />
                      }
                    />
                    <Route
                      path="/order-history"
                      element={
                        <RouteWrapper
                          isAuthed={true}
                          component="order-history"
                        />
                      }
                    />
                    <Route
                      path="/direct/vendors"
                      element={
                        <RouteWrapper
                          isAuthed={true}
                          component="directVendors"
                        />
                      }
                    />
                    <Route
                      path="/direct/vendors/:vendorCode"
                      element={
                        <RouteWrapper
                          isAuthed={true}
                          component="directVendorPage"
                        />
                      }
                    />
                    <Route
                      path="/integrations"
                      element={
                        <RouteWrapper
                          isAuthed={true}
                          component="connection_page"
                        />
                      }
                    />
                    <Route
                      path="/shop"
                      element={
                        <RouteWrapper isAuthed={true} component="dashboard" />
                      }
                    />
                    <Route
                      path="/auth"
                      element={
                        <RouteWrapper isAuthed={true} component="home" />
                      }
                    />
                    <Route
                      path="/home"
                      element={
                        <RouteWrapper isAuthed={true} component="home" />
                      }
                    />
                    <Route
                      path="/cart"
                      element={
                        <RouteWrapper isAuthed={true} component="cart" />
                      }
                    />
                    <Route
                      path="/product/:dgiStyle"
                      element={
                        <RouteWrapper isAuthed={true} component="product" />
                      }
                    />
                    <Route
                      path="/checkout/:vendorCode"
                      element={
                        <RouteWrapper isAuthed={true} component="checkout" />
                      }
                    />
                    <Route
                      path="/direct/checkout"
                      element={
                        <RouteWrapper
                          isAuthed={true}
                          component="directCheckout"
                        />
                      }
                    />
                    <Route
                      path="/confirmation/:vendorCode"
                      element={
                        <RouteWrapper
                          isAuthed={true}
                          component="confirmation"
                        />
                      }
                    />
                    <Route
                      path="/receipt/:vendorCode"
                      element={
                        <RouteWrapper
                          isAuthed={true}
                          component="receipt_page"
                        />
                      }
                    />
                    <Route
                      path="/autopilot"
                      element={
                        <RouteWrapper isAuthed={true} component="invoices" />
                      }
                    />
                    <Route
                      path="/account-settings/:page"
                      element={
                        <RouteWrapper
                          isAuthed={true}
                          component="account-settings"
                        />
                      }
                    />
                    <Route
                      path="/signup"
                      element={
                        <RouteWrapper isAuthed={true} component="signup_page" />
                      }
                    />
                    <Route
                      path="/our-story"
                      element={
                        <RouteWrapper isAuthed={false} component="our-story" />
                      }
                    />
                    <Route
                      path="*"
                      element={
                        isUserAuthed ? (
                          <Navigate to="/home" />
                        ) : (
                          <RouteWrapper
                            isAuthed={false}
                            component="landing-page"
                          />
                        )
                      }
                    />
                  </Routes>
                </BrowserRouter>
              </InstantSearch>
            </ThemeProvider>
          </StyledEngineProvider>
        </CheckoutContext.Provider>
      </DataContext.Provider>
    </UserStateContext.Provider>
  );
}

export default App;
