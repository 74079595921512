import React from "react";
import { Box, Typography } from "@mui/material";

const DGIWatermark = () => (
  <Box
    sx={{
      height: "75px",
      width: "100%",
      bgcolor: "#F6F6F6",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      color: "#d2d6da",
      borderRadius: 1,
    }}
  >
    <Typography
      variant="h2"
      sx={{
        color: "inherit",
        marginBottom: 0,
        fontSize: "1.2rem",
      }}
    >
      DGI
    </Typography>
    <Typography
      variant="body2"
      sx={{
        color: "inherit",
        fontSize: "0.7rem",
        textAlign: "center"
      }}
    >
      Image coming soon
    </Typography>
  </Box>
);

export default DGIWatermark; 