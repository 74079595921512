import React, { useState, useEffect } from "react";
import { Box, Typography, TextField, IconButton, Paper } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import styles from "header/styles.module.scss";
import { constructSwatchImageUrl } from "utilities/imageUrlUtils";
import colorHexMap from "../../constants/colorSwatchHexes.js";

const getHexCode = (color) => {
  const formattedColor = color
    ?.replace(/\s/g, "")
    .replace(/\./g, "")
    .toLowerCase()
    .split("/");
  const hexKey = formattedColor?.join("");
  return colorHexMap[hexKey];
};

const getColorGradient = (hexCodes) => {
  if (!hexCodes) return "transparent";
  if (hexCodes.length === 1) {
    return `linear-gradient(90deg, ${hexCodes[0]} 0%, ${hexCodes[0]} 100%)`;
  }
};

const ColorResultOption = ({
  color,
  isSelected,
  onSelect,
  searchValue,
  dgiStyleSku,
}) => {
  const [imageLoaded, setImageLoaded] = useState("loading");
  const [swatchStyle, setSwatchStyle] = useState({});
  const swatchImageUrl = constructSwatchImageUrl(dgiStyleSku, color);

  useEffect(() => {
    const image = new Image();
    image.src = swatchImageUrl;
    image.onload = () => setImageLoaded("loaded");
    image.onerror = () => setImageLoaded("error");
  }, [swatchImageUrl, color]);

  useEffect(() => {
    if (imageLoaded === "loaded") {
      setSwatchStyle({
        background: `url("${swatchImageUrl}")`,
      });
    } else if (imageLoaded === "error") {
      const colorHexCodes = getHexCode(color);
      const subColorGradient = getColorGradient(colorHexCodes);
      setSwatchStyle({
        background: subColorGradient,
      });
    }
  }, [imageLoaded, color]);

  const highlightMatch = (text, searchValue) => {
    if (!searchValue) return <span className="font-normal">{text}</span>;

    const parts = text.split(new RegExp(`(${searchValue})`, "gi"));
    return parts.map((part, index) =>
      part.toLowerCase() === searchValue.toLowerCase() ? (
        <span key={index} className="font-bold">
          {part}
        </span>
      ) : (
        <span key={index} className="font-normal">
          {part}
        </span>
      )
    );
  };

  return (
    <div
      className={`
        flex items-center space-x-4 px-[12px] py-[8px]
        w-full text-almostSM
        ${isSelected ? "bg-dgiWhite" : "hover:bg-lightestGrey"}
        cursor-pointer text-wolfGrey
      `}
      onClick={() => onSelect(color)}
    >
      <div
        style={{
          ...swatchStyle,
          width: "24px",
          height: "24px",
          borderRadius: "4px",
          border: "1px solid #e0e0e0",
        }}
      />
      <div className="flex-1">
        <Typography>{highlightMatch(color, searchValue)}</Typography>
      </div>
      <input
        type="radio"
        checked={isSelected}
        onChange={() => onSelect(color)}
        className="accent-[rgb(2_129_192)] w-4 h-4"
      />
    </div>
  );
};

const ManualReviewColorSelector = ({
  item,
  onColorSelect,
  availableColors,
  initialSelectedColor,
  selectedStyle,
}) => {
  const [selectedColor, setSelectedColor] = useState(initialSelectedColor || null);
  const [searchValue, setSearchValue] = useState("");

  const handleColorSelect = (color) => {
    setSelectedColor(color);
    if (color) {
      onColorSelect(color);
    }
  };

  useEffect(() => {
    if (initialSelectedColor) {
      setSelectedColor(initialSelectedColor);
    }
  }, [initialSelectedColor]);

  useEffect(() => {
    if (availableColors.length > 0 && initialSelectedColor) {
      const colorToSelect = availableColors.find(color => color === initialSelectedColor);
      if (colorToSelect) {
        handleColorSelect(colorToSelect);
      }
    }
  }, [availableColors, initialSelectedColor]);

  const filteredColors = availableColors?.filter((color) =>
    color?.toLowerCase().includes(searchValue?.toLowerCase())
  );

  return (
    <Box
      sx={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Typography variant="h6" sx={{ mb: 2 }}>
        Select New Color
      </Typography>
      <Box
        sx={{
          flexGrow: 1,
          overflow: "hidden", // Contains the overflow
        }}
      >
        <div
          className={`flex flex-col w-full bg-grayscaleWhite 
          rounded-tr-softer rounded-tl-softer
          rounded-br-softer rounded-bl-softer
          h-full overflow-hidden`}
        >
          <div
            className={`w-full h-12 bg-grayscaleWhite
              rounded-tr-softer rounded-tl-softer
              rounded-br-softer rounded-bl-softer rounded-tr-softer rounded-tl-softer`}
          >
            <div
              className={`flex flex-row items-end gap-[0.5rem] 
                w-full h-full p-[4px]
                rounded-br-softer rounded-bl-softer rounded-tr-softer rounded-tl-softer`}
            >
              <div className="flex-grow h-full w-full relative">
                <TextField
                  fullWidth
                  autoComplete="off"
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                  placeholder="Search for a color..."
                  variant="standard"
                  className="pb-[1px]"
                  InputProps={{
                    disableUnderline: true,
                    className:
                      "custom-search-input bg-grayscaleWhite w-full min-h-full rounded-l-full rounded-r-full flex justify-start gap-[0.75rem] pt-[2px]",
                    classes: {
                      underline: "custom-underline",
                    },
                    startAdornment: (
                      <IconButton
                        className="hover:bg-transparent pt-[6px]"
                        aria-label="search"
                      >
                        <SearchIcon className="fill-charcoal text-[22px]" />
                      </IconButton>
                    ),
                    inputProps: {
                      className: styles.customPlaceholder,
                    },
                  }}
                />
              </div>
            </div>
          </div>
          <Paper
            elevation={0}
            style={{
              width: "100%",
              height: "calc(100% - 48px)",
              overflowY: "auto",
              borderBottomLeftRadius: "25px",
              borderBottomRightRadius: "25px",
              borderTopLeftRadius: "0",
              borderTopRightRadius: "0",
            }}
          >
            <div className="h-full overflow-y-auto rounded-bl-[25px] rounded-br-[25px]">
              {filteredColors?.map((color, index) => (
                <ColorResultOption
                  key={index}
                  color={color}
                  isSelected={selectedColor === color}
                  onSelect={handleColorSelect}
                  searchValue={searchValue}
                  dgiStyleSku={selectedStyle?.dgi_style_sku}
                />
              ))}
            </div>
          </Paper>
        </div>
      </Box>
    </Box>
  );
};

export default ManualReviewColorSelector;
