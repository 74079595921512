import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { TextField, Typography } from "@mui/material";
import HelpIcon from "@mui/icons-material/Help";
import Tooltip from "@mui/material/Tooltip";
import { useCheckoutContext } from "App";
import getTodaysDate from "utilities/getTodaysDate";
import { formatShippingAddress } from "utilities/formatShippingAddress";

const PO_NUMBER_CHAR_LIMIT = 20;

const ShippingLocation = ({ shippingAddrs, setInvalidPONumber }) => {
  const { checkoutFormData, setCheckoutFormData } = useCheckoutContext();
  const [formattedShippingAddrs, setFormattedShippingAddrs] = useState([]);
  const [selectedShippingAddr, setSelectedShippingAddr] = useState(null);
  const [poNumber, setPONumber] = useState("");
  const invalidPONumber = poNumber.length > PO_NUMBER_CHAR_LIMIT;

  useEffect(() => {
    const targetAddr = formattedShippingAddrs.find((addr) => {
      return addr.value === selectedShippingAddr;
    });

    if (targetAddr) {
      const addrDataObj = targetAddr.data;
      setCheckoutFormData((prevCheckoutFormData) => ({
        ...prevCheckoutFormData,
        shippingAddress: addrDataObj,
      }));
    }
  }, [selectedShippingAddr]);

  useEffect(() => {
    setCheckoutFormData((prevCheckoutFormData) => ({
      ...prevCheckoutFormData,
      poNumber: poNumber,
    }));
    if (poNumber.length > PO_NUMBER_CHAR_LIMIT) {
      setInvalidPONumber(true)
    } else {
      setInvalidPONumber(false)
    }
  }, [poNumber]);

  useEffect(() => {
    if (shippingAddrs.length > 0) {
      if (selectedShippingAddr) {
        const targetAddr = formattedShippingAddrs.find(
          (addr) => addr["value"] === selectedShippingAddr,
        );
        const restrictSettingFormattedAddrs =
          formatShippingAddress(shippingAddrs[0]) !== targetAddr["label"];
        if (restrictSettingFormattedAddrs) {
          return;
        }
      }

      const newFormattedShippingAddrs = [];
      for (const [index, addr] of shippingAddrs.entries()) {
        const formattedAddr = formatShippingAddress(addr);
        const addrEntry = {
          value: `addr_${index}`,
          label: formattedAddr,
          data: addr,
        };
        newFormattedShippingAddrs.push(addrEntry);
      }
      setFormattedShippingAddrs(newFormattedShippingAddrs);
    }
  }, [shippingAddrs]);

  useEffect(() => {
    if (formattedShippingAddrs && formattedShippingAddrs[0]) {
      if (!selectedShippingAddr) {
        if (checkoutFormData["shippingAddress"]) {
          const formattedFormShippingAddr = formatShippingAddress(
            checkoutFormData["shippingAddress"],
          );
          const targetShippingAddr = formattedShippingAddrs.find((addr) => {
            return addr["label"] === formattedFormShippingAddr;
          });
          setSelectedShippingAddr(targetShippingAddr["value"]);
        } else {
          setSelectedShippingAddr(formattedShippingAddrs[0]["value"]);
        }
      }
    }
  }, [formattedShippingAddrs]);

  useEffect(() => {
    if (!poNumber) {
      if (checkoutFormData["poNumber"]) {
        setPONumber(checkoutFormData["poNumber"]);
      }
    }
  }, []);

  useEffect(() => {
    if (selectedShippingAddr) {
      const targetAddrIndex = formattedShippingAddrs.findIndex((addr) => {
        return addr["value"] === selectedShippingAddr;
      });
      const targetAddr = formattedShippingAddrs[targetAddrIndex];
      const newFormattedShippingAddrs = [targetAddr].concat(
        formattedShippingAddrs
          .slice(0, targetAddrIndex)
          .concat(formattedShippingAddrs.slice(targetAddrIndex + 1)),
      );
      setFormattedShippingAddrs(newFormattedShippingAddrs);
    }
  }, [selectedShippingAddr]);

  const handleAddressChange = (e) => {
    const newAddress = e.target.value;
    setSelectedShippingAddr(newAddress);
  };

  // radio group can't have a changing default value so we use this check
  // to make sure that the default value is declared only once
  if (!selectedShippingAddr) {
    return null;
  }

  return (
    <Box
      sx={{ marginBottom: 2, marginTop: 0 }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "flex-start",
          marginBottom: 1,
          gap: 2,
        }}
      >
        <Box
          className="checkoutSection"
          sx={{
            width: "70%",
            marginRight: "0",
            borderTop: "none",
            borderLeft: "none",
            paddingBottom: 1,
            backgroundColor: "white",
          }}
        >
          <Box className="bg-dustyBlue" sx={{ p: 1 }}>
            <FormLabel
              component="legend"
              className="font-bold text-grayscaleStone"
            >
              *Ship To Address:
            </FormLabel>
          </Box>
          <FormControl
            component="fieldset"
            sx={{
              padding: 1,
              paddingLeft: 2,
              marginTop: 1,
              maxHeight: "230px",
              overflowY: "auto",
              width: "100%",
              borderRadius: "2px",
            }}
          >
            <RadioGroup
              aria-label="shipping-location"
              value={selectedShippingAddr}
              onChange={handleAddressChange}
              name="radio-buttons-group"
            >
              {formattedShippingAddrs.map((addr, index) => (
                <FormControlLabel
                  key={addr.value}
                  value={addr.value}
                  control={
                    <Radio
                      sx={{
                        span: {
                          color:
                            selectedShippingAddr === addr.value && "rgba(0, 110, 165, 1)", //blueblue
                        },
                      }}
                    />
                  }
                  label={addr.label}
                  sx={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    padding: "6px",
                    backgroundColor: index % 2 === 0 ? "rgba(225, 229, 230, 1)" : "white", // grayscaleSilver or white
                    borderRadius: "4px",
                    marginBottom: "8px",
                    color: "blue",
                  }}
                />
              ))}
            </RadioGroup>
          </FormControl>
        </Box>

        <Box
          className="checkoutSection"
          sx={{
            width: "30%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            borderTop: "none",
            backgroundColor: "white",
          }}
        >
          <Box className="flex items-center bg-dustyBlue" sx={{ p: 1 }}>
            <FormLabel
              htmlFor="po-number"
              sx={{ marginRight: 1 }}
              className="font-bold text-grayscaleStone"
            >
              PO Number
            </FormLabel>
            <Tooltip
              title="PO Number is not required. PO Number will automatically be set to today's date if input is left blank."
              placement="top"
              sx={{
                fontSize: "20px",
              }}
            >
              <HelpIcon className="text-grayscaleStone" sx={{ fontSize: 18 }} />
            </Tooltip>
          </Box>
          <TextField
            id="po-number"
            variant="outlined"
            placeholder={getTodaysDate()}
            sx={{
              outline: invalidPONumber && "1px solid red",
              boxShadow: invalidPONumber && "0 0 4px red",
              width: "calc(100%-2)",
              height: 36,
              margin: 1,
              borderColor: "lightgray",
              borderWidth: "1px",
              borderRadius: "1px",
              "& .MuiOutlinedInput-root": {
                height: "100%",
                "& fieldset": {
                  borderColor: "lightgray",
                  borderRadius: "1px",
                },
                "&:hover fieldset": {
                  borderColor: "gray",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "gray",
                  borderWidth: "1px",
                },
                "&:-webkit-autofill": {
                  "-webkit-box-shadow": "0 0 0 1000px white inset !important",
                  "-webkit-text-fill-color": "gray !important",
                },
                "& .MuiInputBase-input": {
                  marginBottom: "-4px",
                  "&::placeholder": {
                    color: "#9e9e9e",
                    opacity: 1,
                  },
                },          
             },
            }}
            onChange={(e) => setPONumber(e.target.value)}
            value={poNumber}
          />
          {invalidPONumber && (
            <Box sx={{ px: 1.5, py: 1 }}>
              <Typography className="text-warningRed text-base">
                PO Number cannot be more than 20 characters long.
              </Typography>
            </Box> 
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default ShippingLocation;
