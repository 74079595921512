import React from 'react';
import { motion } from 'framer-motion';
import { Box, Paper, Typography } from '@mui/material';
import { FileText, ShoppingCart, ArrowRight } from 'lucide-react';

const SplashPageAnimation = () => {
  const items = [
    '#1234 - 13 Items',
    '#1235 - 47 Items', 
    '#1236 - 11 Items',
    '#1237 - 82 Items'
  ];
  const suppliers = ['Supplier A', 'Supplier B', 'Supplier C'];

  const containerStyles = {
    width: '45%',
    p: 1.5,
    backgroundColor: '#f5f5f5',
    borderRadius: 2,
    height: '180px',
  };

  // Calculate total cycle duration
  const itemDuration = 3; // Increased from 2 to 3 seconds for each item animation
  const itemDelay = 0.3; // Reduced from 0.5 to 0.3 seconds between items
  const totalItemsDuration = items.length * itemDelay + itemDuration;
  const pauseBetweenCycles = 1; // Reduced from 3 to 1 second between cycles

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        maxWidth: '600px',
        height: '220px',
        position: 'relative',
      }}
    >
      {/* Left side - Invoice */}
      <Paper elevation={2} sx={containerStyles}>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 1, gap: 1 }}>
          <FileText size={20} />
          <Typography variant="subtitle2">Printavo Invoices</Typography>
        </Box>
        {items.map((item, index) => (
          <motion.div
            key={index}
            initial={{ x: 0 }}
            animate={{ x: [0, 0, 240], opacity: [1, 1, 0] }}
            transition={{
              duration: itemDuration,
              repeat: Infinity,
              repeatDelay: totalItemsDuration + pauseBetweenCycles - itemDuration,
              delay: index * itemDelay,
              times: [0, 0.8, 1], // Increased from 0.6 to 0.8 to stay longer before moving
            }}
            style={{
              padding: '4px 8px',
              backgroundColor: 'white',
              borderRadius: '4px',
              marginTop: '4px',
              fontSize: '13px',
            }}
          >
            {item}
          </motion.div>
        ))}
      </Paper>

      {/* Center arrow */}
      <Box sx={{ 
        px: 2,
        alignSelf: 'flex-start',
        mt: '30px'  // Align with the headers
      }}>
        <motion.div
          animate={{
            scale: [1, 1, 1.6, 1],
          }}
          transition={{
            duration: itemDuration,
            repeat: Infinity,
            repeatDelay: totalItemsDuration + pauseBetweenCycles - itemDuration,
            times: [0, 0.5, 0.6, 0.75], // Shifted earlier: starts at 60%, peaks at 75%, back to normal by 90%
          }}
        >
          <ArrowRight size={24} color="#2196F3" />
        </motion.div>
      </Box>

      {/* Right side - Supplier Carts */}
      <Paper elevation={2} sx={containerStyles}>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 1, gap: 1 }}>
          <ShoppingCart size={20} />
          <Typography variant="subtitle2">Supplier Carts</Typography>
        </Box>
        <Box>
          {suppliers.map((supplier, index) => (
            <Box
              key={index}
              sx={{
                p: 1,
                mb: 0.5,
                backgroundColor: 'white',
                borderRadius: 1,
                fontSize: '13px',
              }}
            >
              {supplier}
            </Box>
          ))}
        </Box>
      </Paper>
    </Box>
  );
};

export default SplashPageAnimation;
