import React from "react";
import { TableRow, TableCell, Typography } from "@mui/material";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import { LineItemImage } from "./LineItemImage";
import { LineItemCheckbox } from "./LineItemCheckbox";
import { buildProductUrl } from "utilities/navigation";
import { constructImageUrl, getURLColor } from "utilities/imageUrlUtils";

const InvoiceLineItemRow = ({
  item,
  selectedInvoices,
  setSelectedInvoices,
  processingInvoices,
  manualReviewRequired,
  onEditClick,
  confirmedStyle,
  confirmedColor,
  invoiceId,
  allLineItemIds,
}) => {
  // Add this check for overrides
  const hasOverrides = Boolean(confirmedStyle || confirmedColor);

  // Get the current style and color to display
  const displayStyle = (
    confirmedStyle?.master_style ||
    item.master_style ||
    item.style_number ||
    ""
  ).toString();
  const displayColor = (
    confirmedColor ||
    item.master_color ||
    item.color ||
    ""
  ).toString();
  const displayDescription =
    confirmedStyle?.master_short_description ||
    item.master_short_description ||
    item.description ||
    "";

  // Construct image URL based on confirmed values or original values
  const getImageUrl = () => {
    if (item?.images?.length > 0) {
      return item.images[0];
    }

    if (confirmedStyle) {
      return constructImageUrl(
        confirmedStyle.dgi_style_sku,
        getURLColor({ name: confirmedColor || item.color }),
        0
      );
    }
    return item.imgUrl;
  };

  const productUrl = buildProductUrl(
    confirmedStyle?.dgi_style_sku || item.dgi_style_sku,
    confirmedColor || item.master_color || item.color
  );

  // Check if this item is being processed
  const isProcessing = processingInvoices?.some(
    (invoice) =>
      invoice.invoice_id === invoiceId &&
      (invoice.add_all_lines ||
        invoice.line_item_ids.some((id) =>
          item.sizes.some((size) => size.line_item_id === id)
        ))
  );

  return (
    <TableRow
      sx={{
        "&:not(:first-of-type)": {
          borderTop: "1px dashed rgba(0, 0, 0, 0.12)",
        },
      }}
    >
      <TableCell
        sx={{
          verticalAlign: "top",
          width: "100px",
          padding: "8px",
          borderBottom: "none",
        }}
      >
        <LineItemImage src={getImageUrl()} productUrl={productUrl} />
      </TableCell>
      <TableCell
        sx={{
          verticalAlign: "top",
          width: "15%",
          padding: "8px",
          borderBottom: "none",
        }}
      >
        {!item.not_found && productUrl ? (
          <a
            href={productUrl}
            target="_blank"
            rel="noopener noreferrer"
            style={{
              textDecoration: "none",
              color: "inherit",
              cursor: "pointer",
            }}
          >
            <Typography
              component="span"
              sx={{
                textDecoration: "none",
                fontSize: "0.875rem",
                "&:hover": {
                  textDecoration: "underline",
                },
              }}
            >
              {displayStyle}
            </Typography>
          </a>
        ) : (
          <Typography
            component="span"
            sx={{
              fontSize: "0.875rem",
            }}
          >
            {displayStyle}
          </Typography>
        )}
      </TableCell>
      <TableCell
        sx={{
          verticalAlign: "top",
          width: "18%",
          padding: "8px",
          borderBottom: "none",
        }}
      >
        <Typography
          component="span"
          sx={{
            fontSize: "0.875rem",
          }}
        >
          {displayColor}
        </Typography>
      </TableCell>
      <TableCell
        colSpan={2}
        sx={{
          verticalAlign: "top",
          width: "18%",
          padding: "8px",
          borderBottom: "none",
        }}
      >
        <div
          style={{
            display: "flex",
            gap: "8px",
            alignItems: "flex-start",
          }}
        >
          <div
            style={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              gap: "4px",
            }}
          >
            {item.sizes.map((sizeInfo, idx) => (
              <div
                key={idx}
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "8px",
                  justifyContent: "space-between",
                }}
                className={
                  item.not_found && !manualReviewRequired && !hasOverrides
                    ? "bg-warningRed bg-opacity-[15%] p-[2px] rounded-[4px]"
                    : ""
                }
              >
                <div
                  style={{ display: "flex", alignItems: "center", gap: "4px" }}
                >
                  {!manualReviewRequired && (
                    <LineItemCheckbox
                      item={item}
                      selectedInvoices={selectedInvoices}
                      setSelectedInvoices={setSelectedInvoices}
                      lineItemID={sizeInfo.line_item_id}
                      hasOverrides={hasOverrides}
                      invoiceId={invoiceId}
                      allLineItemIds={allLineItemIds}
                      isProcessing={isProcessing}
                      disabled={isProcessing}
                    />
                  )}
                  <div
                    className={
                      item.not_found && !manualReviewRequired && !hasOverrides
                        ? "text-warningRed"
                        : ""
                    }
                  >
                    {sizeInfo.size}:
                  </div>
                </div>
                <div
                  className={
                    item.not_found && !manualReviewRequired && !hasOverrides
                      ? "text-warningRed"
                      : ""
                  }
                >
                  {sizeInfo.quantity}
                </div>
              </div>
            ))}
          </div>
        </div>
      </TableCell>
      <TableCell
        sx={{
          verticalAlign: "top",
          width: "29%",
          padding: "8px",
          paddingLeft: "18px",
          borderBottom: "none",
        }}
      >
        <Typography
          component="span"
          sx={{
            fontSize: "0.875rem",
          }}
        >
          {displayDescription}
        </Typography>
      </TableCell>
      <TableCell
        sx={{
          verticalAlign: "top",
          width: "24px",
          paddingRight: "12px",
          borderBottom: "none",
          textAlign: "center",
        }}
      >
        <BorderColorIcon
          onClick={() => onEditClick(item)}
          sx={{
            cursor: "pointer",
            color: "rgb(2, 129, 192)",
            fontSize: "20px",
            "&:hover": {
              color: "rgb(1, 103, 153)",
            },
          }}
        />
      </TableCell>
    </TableRow>
  );
};

export default InvoiceLineItemRow;
