export const catalogBrands = [
  "24/7",
  "47 Brand",
  "A4",
  "Adams",
  "Adidas",
  "adidas Golf",
  "Alleson Athletic",
  "All Sport",
  "AllMade",
  "Alpine Fleece",
  "Alternative Apparel",
  "American Apparel",
  "Anvil",
  "Artisan Collection by Reprime",
  "Atlantis Headwear",
  "Augusta Sportswear",
  "Authentic Pigment",
  "Badger",
  "BAGedge",
  "Bayside",
  "Beimar Drop Ship",
  "Bella+Canvas",
  "Berne",
  "Big Accessories",
  "Boxercraft",
  "Bright Shield",
  "Brooks Brothers",
  "Burnside",
  "Bulwark",
  "Built",
  "C2 Sport",
  "CAP AMERICA",
  "Carmel Towel Company",
  "Carhartt",
  "Champion",
  "Chef Designs",
  "Classic Caps",
  "Code Five",
  "Code V",
  "Coldie",
  "Columbia",
  "Colortone",
  "Comfort Colors",
  "ComfortWash by Hanes",
  "CORE365",
  "CornerStone",
  "Cotopaxi",
  "Craft Basics",
  "Custom Styles -",
  "DICKIES",
  "Dickies",
  "Dickies Medical",
  "District",
  "Doggie Skins",
  "DRI DUCK",
  "Dyenomite",
  "Eddie Bauer",
  "econscious",
  "Emoti",
  "Extreme",
  "Faribault Woolen Mills",
  "FeatherLite",
  "Flexfit",
  "Fruit of the Loom",
  "Gaiter King",
  "GameTime",
  "Gemline",
  "Gildan",
  "Goofy Group",
  "Hanes",
  "Harriton",
  "Hardware",
  "Helen Jon",
  "High Five",
  "Holloway",
  "Horace Small",
  "Hilton",
  "Ice Shaker",
  "IGLOO",
  "Imperial",
  "In Your Face",
  "Independent Trading Co.",
  "Infinity Her",
  "Inner Harbor",
  "Innovative Designs",
  "IZOD",
  "J. America",
  "Jerzees",
  "Jack Wolfskin",
  "Jerzees",
  "Jonathan Corey",
  "Just Hoods By AWDis",
  "Kanata Blanket",
  "Kastlfel",
  "Kati",
  "Kishigo",
  "Lane Seven",
  "LAT",
  "LEGACY",
  "Leeman",
  "Los Angeles Apparel",
  "M&O",
  "Maui and Sons",
  "Marketing Tools-",
  "Marmot",
  "Mega Cap",
  "MERET",
  "Mercer+Mettle",
  "MopToppers",
  "MV Sport",
  "Native Union",
  "Nautica",
  "New Era",
  "Next Level",
  "Nike",
  "North End",
  "OAKLEY",
  "OAD",
  "OGIO",
  "Outdoor Cap",
  "Outer Banks",
  "Pac-Dent",
  "Palmetto Blanket Company",
  "Paragon",
  "PMI Tape",
  "Port & Company",
  "Port Authority",
  "PRIM + PREUX",
  "Pro Towels",
  "Puma",
  "Q-Tees",
  "Rareform",
  "Red House",
  "Red Kap",
  "Reebok",
  "RefrigiWear",
  "Richardson",
  "Russel Athletic",
  "Russell Athletic",
  "Russell Outdoors",
  "Rubik's",
  "Sierra Pacific",
  "SoftShirts",
  "SOCCO",
  "Spacecraft",
  "Sport-Tek",
  "Sportsman",
  "Spyder",
  "StarTee",
  "SubliVie",
  "Swank",
  "Swankies Golf",
  "Swannies Golf",
  "Tangle Creations",
  "Team 365",
  "The Game",
  "The North Face",
  "The Stadium Chair",
  "Threadfast Apparel",
  "Tommy Bahama",
  "Tommy Hilfiger",
  "Towels Plus",
  "Tri-Mountain",
  "TriDri",
  "TravisMathew",
  "Tultex",
  "UltraClub",
  "Under Armour",
  "US Blanks",
  "VALUCAP",
  "Van Heusen",
  "VERTEX",
  "Volunteer Knitwear",
  "Weatherproof",
  "Willow Pointe",
  "Wonderwink",
  "YP Classics",
  "Yupoong",
  "Zebra",
];
