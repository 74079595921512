import React, { useEffect } from "react";
import { Box, Typography } from "@mui/material";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import LoadedInvoices from "./LoadedInvoices";
import { getURLColor, constructImageUrl } from "utilities/imageUrlUtils";
import { buildProductUrl } from "utilities/navigation";

const ManualInvoices = ({ response, refetch }) => {
  const itemsNotFound = response?.items_not_found || [];

  useEffect(() => {
    if (itemsNotFound.length > 0) {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  }, [itemsNotFound.length]);

  const getImgUrl = (color, dgiStyle) => {
    if (!color || !dgiStyle) return null;
    const urlColor = getURLColor({ name: color });
    return constructImageUrl(dgiStyle, urlColor, 0);
  };

  if (!response || !itemsNotFound || itemsNotFound.length === 0) {
    return null;
  }

  // First, flatten all items into a single array
  const allItems = itemsNotFound.flat();

  // Group items by invoice number
  const invoiceGroups = allItems.reduce((groups, item) => {
    const invoiceNumber = item.invoice_number;
    if (!groups[invoiceNumber]) {
      groups[invoiceNumber] = [];
    }
    groups[invoiceNumber].push(item);
    return groups;
  }, {});

  // Convert grouped items into the format LoadedInvoices expects
  const invoices = Object.entries(invoiceGroups).map(
    ([invoiceNumber, items]) => {
      const firstItem = items[0];
      return {
        invoice_id: `manual-${invoiceNumber}`,
        invoice_number: `Invoice #${invoiceNumber}`,
        total: null,
        total_quantity: items.reduce((sum, item) => sum + item.quantity, 0),
        production_due_date: firstItem.production_due_date,
        dgi_items: items.map((item) => ({
          master_style: item.style_number,
          master_color: item.color,
          size: item.size || "N/A",
          quantity: item.quantity,
          not_found: !item.dgi_style_sku,
          master_short_description: item.description,
          dgi_style_sku: item.dgi_style_sku || item.style_number,
          line_item_id: item.line_item_id,
          invoice_id: `manual-${invoiceNumber}`,
          image_url: getImgUrl(item.color, item.dgi_style_sku),
          reason: item.reason,
          not_found: true,
        })),
        reason: firstItem.reason || "Item not found in catalog",
      };
    }
  );

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "flex-start",
          mb: 2,
        }}
      >
        <WarningAmberIcon
          sx={{
            color: "#f57c00",
            mr: 2,
            fontSize: "32px",
          }}
        />
        <Typography variant="h5" sx={{ color: "#e65100" }}>
          Manual Review Required
        </Typography>
      </Box>
      <LoadedInvoices
        invoices={invoices}
        refetch={refetch}
        manualReviewRequired={true}
      />
    </Box>
  );
};

export default ManualInvoices;
