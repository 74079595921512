import React from "react";
import { Typography, Box } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import POInvoice from "./POInvoice";
import { calculateDiscountDSTotalPrice } from "pages/cart/VendorCart";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { checkoutDisabledVendorNames } from "constants/vendors";

function VendorLogo({ vendorCode, vendorName }) {
  const vendorLogoSrc = `https://d34c0c1in94n75.cloudfront.net/${vendorCode}_logo.png`;
  return (
    <img
      src={vendorLogoSrc}
      alt={vendorName}
      style={{ 
        height: "30px", 
        maxWidth: "180px",
        objectFit: "contain",
        width: "auto"
      }}
    />
  );
}

const PurchaseOrder = ({ vendor }) => {
  const vendorData = vendor[1];
  const vendorName = vendorData.name;
  const vendorCode = vendor[0];
  const isDirectCart = vendorData.isDirectCart;
  const cartItems = vendorData.items;
  const totalPrice = cartItems.reduce(
    (sum, item) =>
      sum + (item.totalPrice || item.unitPrice * item.quantity || 0),
    0
  );
  const freeShippingMin = vendor[1].freeShippingMin;
  // const discountDSTotalPrice = calculateDiscountDSTotalPrice(
  //   vendorData,
  //   vendorCode,
  //   isDirectCart
  // );

  // Calculate total quantity
  const totalQuantity = vendor[1].invoices.reduce((total, invoice) => {
    return total + invoice.invoiceItems.reduce((sum, item) => sum + item.quantity, 0);
  }, 0);

  const navigate = useNavigate();

  const handleProceedToCheckout = () => {
    const vendorURLs = {
      "Alphabroder": "https://www.alphabroder.com/cart",
      "S&S Activewear": "https://www.ssactivewear.com/checkout/cart",
      "Carolina Made": "https://www.carolinamade.com/cgi-bin/live/wam_tmpl/cart.p?site=CMD&layout=Base_b2b&page=cart",
      "Sanmar": "https://www.sanmar.com/cart",
      "Augusta Sportswear": "https://www.augustasportswear.com/Cart",
      "Atlantic Coast Cotton": "https://www.orderacc.com/cgi-bin/liveb2b/wam_tmpl/cart.p?site=ACC&layout=Responsive&page=cart",
      "PrintGear": "https://www.printgear.com/cart",
      "AS Colour": "https://www.ascolour.com/cart",
      "Staton": "https://www.statononline.com/web_checkout?utm_source=dgi&utm_medium=referral&utm_campaign=integration",
      "SLC Activewear": "https://slcactivewear.com/cart?utm_source=dgi_apparel",
    };

    if (checkoutDisabledVendorNames.has(vendorName)) {
      window.open(vendorURLs[vendorName], '_blank');
    } else {
      window.open(`/checkout/${vendorCode}`, '_blank');
    }
  };

  return (
    <Accordion
      elevation={0}
      defaultExpanded
      sx={{
        "&:first-of-type": {
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
        },
        "&:last-of-type": {
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
        },
        margin: 0,
        borderRadius: 0,
        backgroundColor: "transparent",
        borderLeft: "1px solid rgb(2,129,192)",
        "& .MuiPaper-root": {
          borderRadius: 0,
        },
        "& .MuiAccordion-root": {
          borderRadius: 0,
        },
        "& .MuiAccordion-region": {
          borderRadius: 0,
        },
        "&::before": {
          display: "none",
        },
        "&.Mui-expanded": {
          marginY: 0,
        },
        "&:hover": {
          boxShadow:
            "0 15px 35px rgba(50, 50, 93, 0.1), 0 5px 15px rgba(0, 0, 0, 0.07)",
        },
      }}
    >
      <AccordionSummary
        expandIcon={<KeyboardArrowDownIcon className="text-charcoal" />}
        sx={{
          borderRight: "1px solid #e0e3e9",
          borderTop: "1px solid #e0e3e9",
          position: "sticky",
          top: "-1px",
          zIndex: "5",
          backgroundColor: "rgb(245, 247, 250)",
          minHeight: "48px !important",
          height: "48px",
          paddingX: "8px",
          "&:hover": {
            backgroundColor: "#F5F5F5",
          },
          "& .MuiAccordionSummary-content": {
            margin: "0 !important",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
            marginRight: "8px",
          }}
        >
          <Box
            sx={{
              width: "200px",
              padding: "2px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <VendorLogo vendorCode={vendorCode} vendorName={vendorName} />
          </Box>
          <Box
            sx={{
              display: "flex",
              gap: "20px",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <Box sx={{ width: "80px" }}>
              <Typography>
                <span style={{ color: "rgba(0, 0, 0, 0.6)" }}>Items:</span> {totalQuantity}
              </Typography>
            </Box>
            <Box sx={{ width: "140px" }}>
              <Typography sx={{ textAlign: "right" }}>
                <span style={{ color: "rgba(0, 0, 0, 0.6)" }}>Subtotal:</span> ${totalPrice.toLocaleString('en-US', { 
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2 
                })}
              </Typography>
            </Box>
            <Box sx={{ width: "170px", display: "flex", justifyContent: "flex-end" }}>
              <Button
                variant="contained"
                onClick={(e) => {
                  e.stopPropagation();
                  handleProceedToCheckout();
                }}
                sx={{
                  width: '104px',
                  textTransform: 'none',
                  fontSize: '14px',
                  marginRight: '10px',
                  backgroundColor: 'rgb(2,129,192)',
                  boxShadow: 'none',
                  '&:hover': {
                    backgroundColor: 'rgba(0,110,165,1)',
                    boxShadow: 'none',
                  },
                  '&:disabled': {
                    backgroundColor: 'grey',
                  },
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: '32px',
                  gap: '0px',
                }}
              >
                Checkout <ArrowForwardIosIcon sx={{ height: '0.6em', verticalAlign: 'middle', marginBottom: '1px' }} />
              </Button>
            </Box>
          </Box>
        </Box>
      </AccordionSummary>

      <AccordionDetails
        sx={{
          borderRight: "1px solid rgba(225, 229, 230, 1)",
          borderBottom: "1px solid rgba(225, 229, 230, 1)",
          display: "flex",
          flexDirection: "column",
          padding: 0,
        }}
      >
        {vendor[1].invoices.map((data) => {
          return (
            <POInvoice
              key={`cart-invoice-${data.invoiceNumber}`}
              invoiceNumber={data.invoiceNumber}
              invoiceItems={data.invoiceItems}
              total={data.totalPrice}
              vendorCode={vendorCode}
            />
          );
        })}
      </AccordionDetails>
    </Accordion>
  );
};

export default PurchaseOrder;
