import React from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Box,
  Checkbox,
  Tooltip,
  TextField,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import WarehouseRankings from "./WarehouseRankings";

const VendorDropdown = ({ row, preferences = {}, onPreferencesUpdate }) => {
  const [isExpanded, setIsExpanded] = React.useState(false);
  const [isEnabled, setIsEnabled] = React.useState(
    preferences[row.id]?.enabled ?? true
  );
  // const [isDropshipEnabled, setIsDropshipEnabled] = React.useState(preferences[row.id]?.is_dropshipping_enabled ?? false); // Commented out dropshipping
  const [freeShipMin, setFreeShipMin] = React.useState(
    (
      preferences[row.id]?.free_shipping_minimum ?? row.freeShippingMinimum
    ).toString()
  );

  // Add effect to update state when preferences change
  React.useEffect(() => {
    setIsEnabled(preferences[row.id]?.enabled ?? true);
    // setIsDropshipEnabled(preferences[row.id]?.is_dropshipping_enabled ?? false); // Commented out dropshipping
    setFreeShipMin(
      (
        preferences[row.id]?.free_shipping_minimum ?? row.freeShippingMinimum
      ).toString()
    );
  }, [preferences, row.id, row.freeShippingMinimum]);

  const handleCheckboxClick = (event) => {
    event.stopPropagation();
    const newValue = event.target.checked;
    setIsEnabled(newValue);
    onPreferencesUpdate(row.id, { enabled: newValue });
  };

  // Commented out dropshipping handler
  /*const handleDropshipCheckboxClick = (event) => {
    event.stopPropagation();
    const newValue = event.target.checked;
    setIsDropshipEnabled(newValue);
    onPreferencesUpdate(row.id, { is_dropshipping_enabled: newValue });
  };*/

  const handleFreeShipMinChange = (event) => {
    event.stopPropagation();
    const value = event.target.value;

    // Allow empty string for user typing
    setFreeShipMin(value);

    // Only update preferences if it's a valid number
    const numValue = parseInt(value, 10);
    if (!isNaN(numValue) && numValue >= 0) {
      onPreferencesUpdate(row.id, { free_shipping_minimum: numValue });
    }
  };

  const handleFreeShipMinBlur = () => {
    // On blur, ensure we have a valid number
    const numValue = parseInt(freeShipMin, 10);
    if (isNaN(numValue) || numValue < 0) {
      // Reset to previous valid value
      const defaultValue =
        preferences[row.id]?.free_shipping_minimum ?? row.freeShippingMinimum;
      setFreeShipMin(defaultValue.toString());
      onPreferencesUpdate(row.id, { free_shipping_minimum: defaultValue });
    }
  };

  const handleWarehousesReorder = (reorderedWarehouses) => {
    onPreferencesUpdate(row.id, { warehouses: reorderedWarehouses });
  };

  return (
    <Accordion
      onChange={(_, expanded) => setIsExpanded(expanded)}
      className={isExpanded ? "expanded-row" : ""}
      sx={{
        mt: 0,
        borderRadius: 0,
        boxShadow: "none",
        borderTop: "1px solid rgba(0, 0, 0, 0.12)",
        "&::before": {
          display: "none",
        },
        "& .MuiPaper-root": {
          borderRadius: 0,
          boxShadow: "none",
        },
        "&:last-of-type": {
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
        },
        "&:first-of-type": {
          borderTop: "none",
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
        },
        "&.Mui-expanded": {
          margin: "0",
        },
        "& .MuiAccordionSummary-root": {
          borderRadius: 0,
          transition: "none",
          "&:hover": {
            backgroundColor: "#F5F5F5",
          },
          "&.Mui-focused": {
            backgroundColor: "transparent",
          },
          "&:focus-within": {
            backgroundColor: "transparent",
          },
          ".MuiDataGrid-row:hover &": {
            backgroundColor: "#F5F5F5",
          },
          ".expanded-row &": {
            backgroundColor: "transparent !important",
          },
          "&.Mui-expanded": {
            backgroundColor: "#F5F5F5",
          },
        },
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        sx={{
          padding: "0 16px",
          minHeight: "48px !important",
          "& .MuiAccordionSummary-content": {
            margin: "0",
            flex: 1,
            display: "flex",
            alignItems: "center",
          },
          "& .MuiAccordionSummary-expandIconWrapper": {
            marginRight: "8px",
          },
          height: "48px",
          "&.Mui-expanded": {
            minHeight: "48px",
            height: "48px",
          },
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
          <Box
            sx={{
              width: "180px",
              mr: 2,
              display: "flex",
              alignItems: "center",
            }}
          >
            <img
              src={row.logoUrl}
              alt={row.vendor}
              style={{
                height: "24px",
                maxWidth: "170px",
                width: "auto",
                objectFit: "contain",
                objectPosition: "left",
              }}
            />
          </Box>
          <Box sx={{ width: "100px", display: "flex", alignItems: "center" }}>
            <Checkbox
              checked={isEnabled}
              onClick={handleCheckboxClick}
              size="small"
            />
            <Typography variant="body2">Enabled</Typography>
          </Box>
          <Box sx={{ width: "80px" }} />
          <Box sx={{ display: "flex", alignItems: "center", width: "260px" }}>
            <Typography variant="body2" sx={{ mr: 1 }}>
              Free Shipping Min:
            </Typography>
            <Typography variant="body2" sx={{ mr: 0.2 }}>$</Typography>
            <TextField
              value={freeShipMin}
              onChange={handleFreeShipMinChange}
              onBlur={handleFreeShipMinBlur}
              onClick={(e) => e.stopPropagation()}
              size="small"
              type="number"
              inputProps={{ min: 0 }}
              sx={{
                width: "50px",
                marginBottom: "3px",
                "& .MuiOutlinedInput-root": {
                  height: "32px",
                  "& input": {
                    padding: "8px 8px",
                    position: "relative",
                    top: "2px",
                    "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
                      "-webkit-appearance": "none",
                      margin: 0,
                    },
                    "&[type=number]": {
                      "-moz-appearance": "textfield",
                    },
                  },
                },
              }}
            />
          </Box>
        </Box>
        <Typography
          variant="body2"
          sx={{
            color: "text.secondary",
            position: "absolute",
            right: "48px",
            marginRight: "4px",
            "&:hover": {
              textDecoration: "underline",
              cursor: "pointer",
            },
          }}
        >
          Warehouses
        </Typography>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          boxShadow: "none",
          padding: 0,
          borderTop: "1px dashed rgba(0, 0, 0, 0.12)",
          borderRadius: 0,
          "& .MuiPaper-root": {
            borderRadius: 0,
          },
        }}
      >
        <WarehouseRankings 
          vendorId={row.id} 
          warehouses={row.warehouses} 
          onWarehousesReorder={handleWarehousesReorder}
        />
      </AccordionDetails>
    </Accordion>
  );
};

export default VendorDropdown;
