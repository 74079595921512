import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
  Grid,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ManualReviewStyleSelector from './ManualReviewStyleSelector';
import ManualReviewColorSelector from './ManualReviewColorSelector';
import { usePostHog } from "posthog-js/react";

const ManualReviewPopup = ({ 
  open, 
  onClose,
  onSave, 
  item,
  selectedStyle,
  setSelectedStyle,
  selectedColor,
  setSelectedColor 
}) => {
  const [availableColors, setAvailableColors] = useState([]);
  const posthog = usePostHog();

  const handleStyleSelect = (style) => {
    setSelectedStyle(style);
  };

  const handleColorSelect = (color) => {
    if (color) {
      setSelectedColor(color);
    }
  };

  const handleSave = () => {
    if (!selectedStyle || !selectedColor) return;
    onSave();
    
    posthog?.capture('manual_override', {
      selectedStyle: {
        style: selectedStyle.master_style,
        dgi_style_sku: selectedStyle.dgi_style_sku,
        description: selectedStyle.master_short_description,
        brand: selectedStyle.master_brand,
        color: selectedColor,
      },
      originalItem: {
        color: item.color,
        description: item.description,
        style: item.style_number,
      }
    });
  };

  return (
    <Dialog 
      open={open} 
      onClose={onClose}
      maxWidth="md"
      fullWidth
      PaperProps={{
        sx: {
          height: '80vh',
          maxHeight: '600px',
          display: 'flex',
          flexDirection: 'column'
        }
      }}
    >
      <DialogTitle sx={{ 
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center',
        borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
        padding: '16px 24px',
      }}>
        <Box>
          <Typography variant="h6" sx={{ mb: 1 }}>
            Original Invoice Item
          </Typography>
          <Typography variant="body1">
            <span style={{ color: 'rgba(0, 0, 0, 0.6)' }}>Item: </span>
            <span style={{ color: '#373737' }}>{item?.style_number}</span>
            <span style={{ color: 'rgba(0, 0, 0, 0.6)' }}> | Color: </span>
            <span style={{ color: '#373737' }}>{item?.color}</span>
          </Typography>
          <Typography variant="body1">
            <span style={{ color: 'rgba(0, 0, 0, 0.6)' }}>Description: </span>
            <span style={{ color: '#373737' }}>{item?.description}</span>
          </Typography>
        </Box>
        <CloseIcon 
          onClick={onClose}
          sx={{ 
            cursor: 'pointer',
            color: 'rgba(0, 0, 0, 0.54)',
            '&:hover': {
              color: 'rgba(0, 0, 0, 0.87)',
            }
          }}
        />
      </DialogTitle>
      <DialogContent sx={{ 
        padding: '24px', 
        height: '100%',
        '&.MuiDialogContent-root': {
          paddingBottom: 0
        }
      }}>
        <Box
          sx={{
            display: 'flex',
            gap: '24px',
            position: 'relative',
            height: '100%',
          }}
        >
          <Box
            sx={{
              flex: 1,
              padding: '16px',
              position: 'relative',
              height: '100%',
              '&::after': {
                content: '""',
                position: 'absolute',
                right: '-12px',
                top: 0,
                bottom: 0,
                borderRight: '1px dashed rgba(0, 0, 0, 0.12)',
              }
            }}
          >
            <ManualReviewStyleSelector 
              item={item} 
              onStyleSelect={handleStyleSelect}
              selectedStyle={selectedStyle}
              setAvailableColors={setAvailableColors}
              setSelectedColor={setSelectedColor}
              initialSearchValue={selectedStyle?.master_style || ''}
            />
          </Box>
          <Box
            sx={{
              flex: 1,
              padding: '16px',
              height: '100%',
            }}
          >
            <ManualReviewColorSelector 
              item={item}
              onColorSelect={handleColorSelect}
              availableColors={availableColors}
              initialSelectedColor={selectedColor}
              selectedStyle={selectedStyle}
            />
          </Box>
        </Box>
      </DialogContent>
      <DialogActions sx={{ 
        borderTop: '1px solid rgba(0, 0, 0, 0.12)',
        padding: '16px 24px',
      }}>
        <Button 
          onClick={handleSave}
          disabled={!selectedStyle || !selectedColor}
          variant="contained"
          sx={{ 
            backgroundColor: 'rgb(2, 129, 192)',
            '&:hover': {
              backgroundColor: 'rgb(1, 103, 153)',
            }
          }}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ManualReviewPopup;
