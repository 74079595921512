import React, { useState, useEffect } from "react";
import { TableContainer, Table, TableBody, Paper } from "@mui/material";
import { getURLColor, constructImageUrl } from "utilities/imageUrlUtils";
import ManualReviewPopup from "./ManualReviewPopup";
import InvoiceLineItemRow from "./InvoiceLineItemRow";
import { useDispatch } from "react-redux";
import { setConfirmedOverride } from "features/invoicesSlice";

const InvoiceLineItems = ({
  dgiLineItems,
  invoiceId,
  setDgiCatalogMatch,
  selectedInvoices,
  setSelectedInvoices,
  manualReviewRequired,
  processingInvoices,
}) => {
  const [openPopup, setOpenPopup] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedStyles, setSelectedStyles] = useState({});
  const [selectedColors, setSelectedColors] = useState({});
  const [confirmedStyles, setConfirmedStyles] = useState({});
  const [confirmedColors, setConfirmedColors] = useState({});
  const dispatch = useDispatch();

  // Helper function to generate item key
  const getItemKey = (item) => {
    return `${item.master_style || item.style_number}-${
      item.master_color || item.color
    }-${item.line_item_id?.replace(/-[^-]*$/, "")}`;
  };

  const getImgUrl = (item) => {
    if (item?.images?.length > 0) {
      return item.images[0];
    }
    const urlColor = getURLColor({ name: item?.master_color || item?.color });
    return constructImageUrl(item?.dgi_style_sku, urlColor, 0);
  };

  // Group items by style, color, and line item ID
  const groupedItems = dgiLineItems.reduce((acc, item) => {
    const baseLineItemId = item.line_item_id?.replace(/-[^-]*$/, ""); // Remove everything after the last hyphen
    const key = `${item.master_style || item.style_number}-${
      item.master_color || item.color
    }-${baseLineItemId}`;

    // Only mark as fallback if it's not_found AND has no override
    const itemKey = getItemKey(item);
    const isFallback = item.not_found && !(confirmedStyles[itemKey] && confirmedColors[itemKey]);

    if (isFallback) {
      setDgiCatalogMatch(false);
    }

    if (!acc[key]) {
      acc[key] = {
        ...item,
        sizes: [
          {
            size: item.size,
            quantity: item.quantity,
            line_item_id: item.line_item_id,
          },
        ],
        isFallback,
      };
    } else {
      acc[key].sizes.push({
        size: item.size,
        quantity: item.quantity,
        line_item_id: item.line_item_id, // Store the line item ID with the size
      });
    }
    return acc;
  }, {});

  useEffect(() => {
    // Check if any items have not_found flag
    const hasNotFoundItems = Object.values(groupedItems).some(
      (item) => item.isFallback
    );
    if (hasNotFoundItems) {
      setDgiCatalogMatch(false);
    }
  }, [groupedItems, setDgiCatalogMatch]);

  const handleSaveManualReview = () => {
    if (!selectedItem) return;

    const itemKey = getItemKey(selectedItem);
    const newStyle = selectedStyles[itemKey];
    const newColor = selectedColors[itemKey];

    // Create updated version of confirmedStyles/Colors that includes the new changes
    const updatedConfirmedStyles = {
      ...confirmedStyles,
      [itemKey]: newStyle,
    };
    const updatedConfirmedColors = {
      ...confirmedColors,
      [itemKey]: newColor,
    };

    setConfirmedStyles(updatedConfirmedStyles);
    setConfirmedColors(updatedConfirmedColors);

    // Dispatch an override for each size of this item
    selectedItem.sizes.forEach((size) => {
      dispatch(
        setConfirmedOverride({
          invoiceId: invoiceId,
          lineItemId: size.line_item_id,
          style: newStyle,
          color: newColor,
          quantity: size.quantity,
        })
      );
    });

    // Check if all not_found items now have overrides using the updated values
    const notFoundItems = dgiLineItems.filter(item => item.not_found);
    const allNotFoundItemsHaveOverrides = notFoundItems.every(item => {
      const itemKey = getItemKey(item);
      return Boolean(updatedConfirmedStyles[itemKey] && updatedConfirmedColors[itemKey]);
    });

    if (allNotFoundItemsHaveOverrides) {
      setDgiCatalogMatch(true);
    }

    setOpenPopup(false);
    setSelectedItem(null);
  };

  // Get all line item IDs for this invoice
  const allLineItemIds = dgiLineItems.flatMap((item) =>
    item.line_item_id ? [item.line_item_id] : []
  );

  return (
    <TableContainer
      component={Paper}
      sx={{
        borderRadius: 0,
        "& .MuiPaper-root": {
          borderRadius: 0,
        },
      }}
    >
      <Table>
        <TableBody>
          {Object.values(groupedItems).map((item, index) => {
            const itemKey = getItemKey(item);

            return (
              <InvoiceLineItemRow
                key={index}
                item={{
                  ...item,
                  imgUrl: getImgUrl(item),
                }}
                selectedInvoices={selectedInvoices}
                setSelectedInvoices={setSelectedInvoices}
                processingInvoices={processingInvoices}
                manualReviewRequired={manualReviewRequired}
                onEditClick={(item) => {
                  setSelectedItem(item);
                  setOpenPopup(true);
                }}
                confirmedStyle={confirmedStyles[itemKey]}
                confirmedColor={confirmedColors[itemKey]}
                invoiceId={invoiceId}
                allLineItemIds={allLineItemIds}
              />
            );
          })}
        </TableBody>
      </Table>
      <ManualReviewPopup
        open={openPopup}
        onClose={() => {
          setOpenPopup(false);
          setSelectedItem(null);
        }}
        onSave={handleSaveManualReview}
        item={selectedItem}
        selectedStyle={
          selectedItem ? selectedStyles[getItemKey(selectedItem)] : null
        }
        setSelectedStyle={(style) => {
          if (!selectedItem) return;
          const itemKey = getItemKey(selectedItem);
          setSelectedStyles((prev) => ({
            ...prev,
            [itemKey]: style,
          }));
        }}
        selectedColor={
          selectedItem ? selectedColors[getItemKey(selectedItem)] : null
        }
        setSelectedColor={(color) => {
          if (!selectedItem) return;
          const itemKey = getItemKey(selectedItem);
          setSelectedColors((prev) => ({
            ...prev,
            [itemKey]: color,
          }));
        }}
      />
    </TableContainer>
  );
};

export default InvoiceLineItems;
