import React, { useState, useEffect } from "react";
import {
  Button,
  CircularProgress,
  Box,
  Skeleton,
  Typography,
  TextField,
  IconButton,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import LoadedInvoices from "./LoadedInvoices";
import { useDispatch, useSelector } from "react-redux";
import ManualInvoices from "./ManualInvoices";
import { useWebsocketContext } from "authsignin/AuthedPage";
import {
  selectInvoices,
  selectInvoicesLoading,
  selectInvoicesError,
  setConfirmedOverride,
} from "features/invoicesSlice";
import {
  selectAutoAddLoading,
  selectItemsNotFound,
  selectHasUpdatedCarts,
  selectPOData,
  resetAutoAdd,
  setShowUpdatedCartsAlert,
  selectShowUpdatedCartsAlert,
  selectAutoAddError,
} from "features/autoAddSlice";
import { selectCarts } from "features/cartsSlice";
import AlertSnackbar from "alerts/AlertSnackbar";
import SettingsIcon from '@mui/icons-material/Settings';
import PreferencesPopup from './autopilot-preferences/PreferencesPopup';

const InvoiceActionButtons = ({
  refetch,
  selectedInvoices,
  setSelectedInvoices,
  setProcessingInvoices,
}) => {
  const { sendDgiJsonMessage } = useWebsocketContext();
  const isAddingToCarts = useSelector(selectAutoAddLoading);
  const showUpdatedCartsAlert = useSelector(selectShowUpdatedCartsAlert);
  const autoAddError = useSelector(selectAutoAddError);
  const noInvoicesSelected = selectedInvoices.length === 0;
  const dispatch = useDispatch();

  // Move cart selector to component level
  const currentCarts = useSelector((state) => state?.carts?.carts || {});
  console.log("currentCarts", currentCarts);

  // Add this selector to get the confirmed overrides
  const confirmedOverrides =
    useSelector((state) => state.invoices.confirmedOverrides) || {};

  const handleAddToCart = () => {
    if (selectedInvoices.length === 0) {
      console.error("Please select at least one invoice to add to cart");
      return;
    }

    // Set processing invoices before sending request
    setProcessingInvoices(selectedInvoices);

    // Get invoice_ids where all lines are selected
    const invoice_ids = selectedInvoices
      .filter((invoice) => invoice.add_all_lines)
      .map((invoice) => invoice.invoice_id);

    // Get partial invoice objects where only some lines are selected
    const partial_invoice_ids = selectedInvoices.filter(
      (invoice) => !invoice.add_all_lines
    );

    // Build the invoice_line_item_overrides object
    const invoice_line_item_overrides = {};

    // For each invoice that has overrides
    Object.entries(confirmedOverrides).forEach(([invoiceId, lineItems]) => {
      // Only include overrides for selected invoices/line items
      const selectedInvoice = selectedInvoices.find(
        (inv) => inv.invoice_id === invoiceId
      );
      if (!selectedInvoice) return;

      invoice_line_item_overrides[invoiceId] = {};

      // For each line item in this invoice
      Object.entries(lineItems).forEach(([lineItemId, override]) => {
        // Only include if this line item is selected
        if (
          selectedInvoice.add_all_lines ||
          selectedInvoice.line_item_ids.includes(lineItemId)
        ) {
          invoice_line_item_overrides[invoiceId][lineItemId] = {
            dgi_style_sku: override.confirmedStyle?.dgi_style_sku,
            master_color: override.confirmedColor,
            quantity: override.quantity,
          };
        }
      });

      // Remove empty invoice entries
      if (Object.keys(invoice_line_item_overrides[invoiceId]).length === 0) {
        delete invoice_line_item_overrides[invoiceId];
      }
    });

    // Create vendor_cart_totals object from current cart data
    const vendor_cart_totals = {};
    Object.entries(currentCarts).forEach(([vendorCode, cartData]) => {
      if (cartData && typeof cartData.totalPrice === 'number') {
        vendor_cart_totals[vendorCode] = cartData.totalPrice;
      }
    });

    // Only include vendor_cart_totals if we have data
    const payload = {
      action: "auto_add",
      invoice_ids,
      partial_invoice_ids,
      invoice_line_item_overrides:
        Object.keys(invoice_line_item_overrides).length > 0
          ? invoice_line_item_overrides
          : undefined,
    };

    if (Object.keys(vendor_cart_totals).length > 0) {
      payload.vendor_cart_totals = vendor_cart_totals;
    }

    sendDgiJsonMessage(payload);

    // Don't reset selected invoices immediately
    // setSelectedInvoices([]);
  };

  let submitBtnContent = null;
  if (isAddingToCarts) {
    submitBtnContent = <CircularProgress size={24} />;
  } else {
    submitBtnContent = "Add To My Carts";
  }

  return (
    <>
      <Box className="flex justify-between px-[36px]" sx={{ mt: "12px" }}>
        <Button
          className="normal-case text-lighterblueblue text-base"
          onClick={refetch}
          sx={{
            border: "1px solid #ADD8E6",
            backgroundColor: "#FFFFFF",
            "&:hover": {
              backgroundColor: "#F5F5F5",
            },
          }}
        >
          Refresh Invoices
        </Button>
        <Button
          className="normal-case bg-lighterblueblue text-dgiWhite text-base hover:bg-denim"
          onClick={handleAddToCart}
          disabled={isAddingToCarts || noInvoicesSelected}
          style={{ textTransform: "none" }}
          sx={{
            "&.Mui-disabled": {
              backgroundColor: "#D3D3D3",
              color: "#A9A9A9",
            },
          }}
        >
          {submitBtnContent}
        </Button>
      </Box>
      <AlertSnackbar
        open={showUpdatedCartsAlert}
        severity="success"
        text="Finished updating your carts!"
        handleClose={() => {
          dispatch(setShowUpdatedCartsAlert(false));
        }}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      />
      <AlertSnackbar
        open={!!autoAddError}
        severity="error"
        text={autoAddError || ""}
        handleClose={() => {
          dispatch(resetAutoAdd());
        }}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      />
    </>
  );
};

const LoadedInvoiceContent = ({ invoices, refetch }) => {
  const [selectedInvoices, setSelectedInvoices] = useState([]);
  const [processingInvoices, setProcessingInvoices] = useState([]);
  const itemsNotFound = useSelector(selectItemsNotFound);
  const [searchQuery, setSearchQuery] = useState("");
  const [preferencesOpen, setPreferencesOpen] = useState(false);
  const isAddingToCarts = useSelector(selectAutoAddLoading);

  const filteredInvoices = invoices
    .filter((invoice) =>
      invoice.invoice_number.toLowerCase().includes(searchQuery.toLowerCase())
    )
    .sort((a, b) => b.invoice_number.localeCompare(a.invoice_number));

  const handleSelectAll = () => {
    if (isAddingToCarts) return; // Early return if processing
    const allSelected = filteredInvoices.map((invoice) => ({
      invoice_id: invoice.invoice_id,
      line_item_ids: [],
      add_all_lines: true,
    }));
    setSelectedInvoices(allSelected);
  };

  const handleSelectNone = () => {
    if (isAddingToCarts) return; // Early return if processing
    setSelectedInvoices([]);
  };

  // Extract selected invoice numbers
  const selectedInvoiceNumbers = invoices
    .filter((invoice) =>
      selectedInvoices.some(
        (selected) => selected.invoice_id === invoice.invoice_id
      )
    )
    .map((invoice) => invoice.invoice_number);

  useEffect(() => {
    if (!isAddingToCarts) {
      setProcessingInvoices([]);
      setSelectedInvoices([]); // Only clear selections after processing is done
    }
  }, [isAddingToCarts]);

  return (
    <>
      <ManualInvoices
        response={{ items_not_found: itemsNotFound }}
        refetch={refetch}
      />
      {invoices.length === 0 ? (
        <Box
          className="flex items-center justify-center"
          sx={{
            height: "300px",
            width: "100%",
            border: "1px solid #ccc",
          }}
        >
          <Typography variant="h6">No Invoices Found</Typography>
        </Box>
      ) : (
        <Box className="flex flex-col" sx={{ height: "calc(100vh - 230px)" }}>
          <Box sx={{ flexShrink: 0 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <Typography variant="h5">Ready to Order</Typography>
              <IconButton 
                onClick={() => setPreferencesOpen(true)}
                sx={{ color: '#999999' }}
              >
                <SettingsIcon />
              </IconButton>
            </Box>
            <Box
              sx={{
                display: "flex",
                gap: 2,
                my: "14px",
                ml: "2px",
                alignItems: "flex-end",
              }}
            >
              <Typography
                onClick={handleSelectAll}
                sx={{
                  cursor: isAddingToCarts ? "not-allowed" : "pointer",
                  color: isAddingToCarts
                    ? "rgba(2, 129, 192, 0.5)"
                    : "rgb(2, 129, 192)",
                  fontSize: "0.875rem",
                  "&:hover": {
                    textDecoration: isAddingToCarts ? "none" : "underline",
                  },
                }}
              >
                SELECT ALL
              </Typography>
              <Typography
                onClick={handleSelectNone}
                sx={{
                  cursor: isAddingToCarts ? "not-allowed" : "pointer",
                  color: isAddingToCarts
                    ? "rgba(2, 129, 192, 0.5)"
                    : "rgb(2, 129, 192)",
                  fontSize: "0.875rem",
                  "&:hover": {
                    textDecoration: isAddingToCarts ? "none" : "underline",
                  },
                }}
              >
                SELECT NONE
              </Typography>
              <Box sx={{ flexGrow: 1 }} />
              <TextField
                size="small"
                placeholder="Search invoices..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                autoComplete="off"
                sx={{
                  width: "200px",
                  borderRadius: "2px",
                  "& .MuiOutlinedInput-input": {
                    paddingTop: "10px",
                    paddingBottom: "7px",
                  },
                }}
              />
            </Box>
          </Box>
          <Box sx={{ flexGrow: 1, overflow: "auto" }}>
            <LoadedInvoices
              invoices={filteredInvoices}
              selectedInvoices={selectedInvoices}
              setSelectedInvoices={setSelectedInvoices}
              processingInvoices={processingInvoices}
              searchQuery={searchQuery}
            />
          </Box>
          <Typography sx={{ mt: 1, mb: 0 }}>
            {selectedInvoiceNumbers.length} Invoices Selected:{" "}
            {selectedInvoiceNumbers.join(", ")}
          </Typography>
        </Box>
      )}
      <PreferencesPopup 
        open={preferencesOpen}
        onClose={() => setPreferencesOpen(false)}
      />
      <InvoiceActionButtons
        refetch={refetch}
        selectedInvoices={selectedInvoices}
        setSelectedInvoices={setSelectedInvoices}
        setProcessingInvoices={setProcessingInvoices}
      />
    </>
  );
};

const InvoicePage = () => {
  const { fetchInvoices } = useWebsocketContext();
  const invoices = useSelector(selectInvoices);
  const isLoading = useSelector(selectInvoicesLoading);
  const error = useSelector(selectInvoicesError);

  if (error) {
    return (
      <Typography color="error" sx={{ mt: 2 }}>
        Could not load invoices. Contact the team if the problem persists.
      </Typography>
    );
  }

  return (
    <Box className="flex flex-col space-y-14" sx={{ mt: "1rem" }}>
      {isLoading ? (
        <Box className="flex flex-col">
          <Typography variant="h5" sx={{ mb: "24px" }}>
            Loading Invoices...
          </Typography>
          <Box className="flex flex-col space-y-0.5">
            {[...Array(4)].map((_, index) => (
              <Skeleton
                key={index}
                variant="rectangular"
                height={48}
                width="100%"
              />
            ))}
          </Box>
        </Box>
      ) : (
        <LoadedInvoiceContent invoices={invoices} refetch={fetchInvoices} />
      )}
    </Box>
  );
};

export default InvoicePage;
