import React from "react";
import { Checkbox } from "@mui/material";

export const LineItemCheckbox = ({
  item,
  selectedInvoices,
  setSelectedInvoices,
  lineItemID,
  hasOverrides,
  invoiceId,
  allLineItemIds,
  isProcessing,
  disabled,
}) => {
  const selectedInvoice = selectedInvoices.find(
    (i) => i.invoice_id === invoiceId
  );

  const isChecked =
    Boolean(selectedInvoice?.add_all_lines) ||
    Boolean(selectedInvoice?.line_item_ids?.includes(lineItemID));

  const pickInvoiceLineItems = (e) => {
    const { checked } = e.target;

    setSelectedInvoices((prev) => {
      const existingIndex = prev.findIndex((i) => i.invoice_id === invoiceId);

      if (!checked) {
        // Unchecking a line item
        if (existingIndex === -1) return prev;

        const existing = prev[existingIndex];

        if (existing.add_all_lines) {
          // If previously all lines were selected, switch to individual selection
          // Use allLineItemIds instead of item.sizes
          const newLineItems = allLineItemIds.filter((id) => id !== lineItemID);

          return [
            ...prev.slice(0, existingIndex),
            {
              invoice_id: invoiceId,
              line_item_ids: newLineItems,
              add_all_lines: false,
            },
            ...prev.slice(existingIndex + 1),
          ];
        }

        // Normal case - just remove this line item
        const newLineItems = existing.line_item_ids.filter(
          (id) => id !== lineItemID
        );
        if (newLineItems.length === 0) {
          // If this was the last line item, remove the invoice entirely
          return prev.filter((i) => i.invoice_id !== invoiceId);
        }
        return [
          ...prev.slice(0, existingIndex),
          { ...existing, line_item_ids: newLineItems },
          ...prev.slice(existingIndex + 1),
        ];
      } else {
        // Checking a line item
        if (existingIndex === -1) {
          // First item selected for this invoice - add both invoice and line item
          return [
            ...prev,
            {
              invoice_id: invoiceId,
              line_item_ids: [lineItemID],
              add_all_lines: false,
            },
          ];
        }

        // Add line item to existing invoice selection
        const existing = prev[existingIndex];
        const newLineItems = [
          ...new Set([...existing.line_item_ids, lineItemID]),
        ];

        return [
          ...prev.slice(0, existingIndex),
          {
            ...existing,
            line_item_ids: newLineItems,
            add_all_lines: false, // Ensure we're in line item selection mode
          },
          ...prev.slice(existingIndex + 1),
        ];
      }
    });
  };

  return (
    <Checkbox
      checked={isChecked}
      onChange={(e) => pickInvoiceLineItems(e)}
      onClick={(e) => e.stopPropagation()}
      disabled={(item.not_found && !hasOverrides) || disabled}
      sx={{
        padding: "0px",
        "& .MuiSvgIcon-root": { fontSize: 20 },
        ...((hasOverrides || isProcessing) && {
          "&.Mui-checked": {
            color: isProcessing ? "#9e9e9e" : "rgb(2, 129, 192)",
          },
        }),
      }}
    />
  );
};
