import React, { useState, useEffect } from "react";
import { useMediaQuery } from "@mui/material";

import styles from "./styles.module.scss";
import CartFilterBar from "./CartFilterBar";
import CartCheckout from "./CartCheckout";
import { useDataContext } from "../../App";
import { useSelector } from "react-redux";
import vendors from "constants/vendors";

export default function CartPage() {
  const [selectedFilter, setSelectedFilter] = useState("All Carts");
  const [loadingCarts, setLoadingCarts] = useState(false);
  const { connectedVendorCodes } = useDataContext();
  const allCarts = useSelector((state) => state.carts?.carts);
  const isSmallerScreen = useMediaQuery('(max-width:1130px)');

  useEffect(() => {
    if (isSmallerScreen) {
      setSelectedFilter("All Carts");
    }
  }, [isSmallerScreen]);

  const displayFilters = [];
  displayFilters.push("All Carts");
  const nonDirectCodes = Object.values(vendors).map((v) => v.code);
  for (const vendorCode of Object.keys(allCarts)) {
    if (vendorCode === "direct") {
      // ignoring non-cart we use to track loading state
      continue;
    }
    const dataKey = vendorCode + "_data";
    const isDirectCode = !Boolean(
      nonDirectCodes.find((code) => code === vendorCode)
    );
    if (connectedVendorCodes[dataKey] || isDirectCode) {
      displayFilters.push(vendorCode);
    }
  }

  return (
    <div className={styles.cartPage}>
      <CartFilterBar
        selectedFilter={selectedFilter}
        setSelectedFilter={setSelectedFilter}
        displayFilters={displayFilters}
        allCarts={allCarts}
      />
      <CartCheckout
        selectedFilter={selectedFilter}
        loadingCarts={loadingCarts}
        setLoadingCarts={setLoadingCarts}
        allCarts={allCarts}
      />
    </div>
  );
}
