import React, { useState, useEffect } from "react";

import styles from "./styles.module.scss";
import CheckoutItemPriceEntry from "./CheckoutItemPriceEntry";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import {
  createCartEntry,
  createSwitchWarehouseBody,
} from "../../utilities/createCartEntry";
import { formatPrice } from "../../utilities/formatPrice";
import { Stack, Box, Typography } from "@mui/material";

import { useUserStateContext } from "../../App";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { buildProductUrl } from "utilities/navigation";
import { updateItems, handleRemoveItem } from "../../utilities/cartPageOperations";

const capitalizeWords = (str) => {
  if (str == undefined) {
    return str;
  }
  return str
    .toLowerCase()
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

const ProductImage = ({ itemImageUrl }) => {
  const [displayProductImage, setDisplayProductImage] = useState(true);

  useEffect(() => {
    if (itemImageUrl && !displayProductImage) {
      setDisplayProductImage(true);
    }
  }, [itemImageUrl]);

  if (!displayProductImage) {
    return null;
  }

  return (
    <img
      className={styles.checkoutItemImage}
      src={itemImageUrl}
      alt={null}
      onError={() => setDisplayProductImage(false)}
    />
  );
};

function CaseQuantityNotification({ caseQtyRequired, caseQty }) {
  if (!caseQtyRequired) {
    return null;
  }
  return (
    <Typography sx={{ fontWeight: "600" }}>
      * Must order in case quantity increments of {caseQty}
    </Typography>
  );
}

export default function CheckoutItem({
  lastItem,
  vendorCode,
  checkoutItemEntries,
  isDirectCart,
}) {
  const [backdropOpen, setBackdropOpen] = useState(false);
  const { userCreds } = useUserStateContext();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const firstItem = checkoutItemEntries[0];
  const itemStyle = firstItem?.style;
  const itemDescription = firstItem?.description;
  const dgiStyleSKU = firstItem?.dgi_style_sku;
  const masterColor = firstItem?.master_color;
  const itemImageUrl = firstItem?.product_image_url;

  let itemSubtotal = 0.0;
  for (const item of checkoutItemEntries) {
    const additiveTotal =
      item?.totalPrice || Number(item?.unitPrice) * Number(item?.quantity);
    itemSubtotal += additiveTotal;
  }

  async function handleUpdateWarehouse(
    code,
    item,
    warehouse,
    onSuccess,
    onError
  ) {
    setBackdropOpen(true);
    const updatePayload = createSwitchWarehouseBody(code, item, warehouse);
    return await updateItems(
      code,
      updatePayload,
      onSuccess,
      onError,
      dispatch,
      isDirectCart,
      setBackdropOpen,
      userCreds.cognitoID
    );
  }

  async function handleUpdateItem(code, item, quantity, onSuccess, onError) {
    setBackdropOpen(true);
    const itemWhse =
      vendorCode === "ab" ||
      vendorCode === "cm" ||
      vendorCode === "acc" ||
      vendorCode === "sm" ||
      vendorCode === "sta"
        ? item.warehouseCode.toLowerCase()
        : item.warehouse;
    const updateItem = isDirectCart
      ? {
          ...item,
          vendorCode: code,
          warehouse: itemWhse,
          updatingEntry: true,
          quantity,
        }
      : createCartEntry(code, itemWhse, quantity, null, item);
    const body = {
      items: Array(updateItem),
    };
    if (code === "ss") {
      body["product_url_short"] = item.ss_product_url_short;
    } else if (code === "asc") {
      body["asc_product_id"] = item.asc_product_id;
    }
    return await updateItems(
      code,
      body,
      onSuccess,
      onError,
      dispatch,
      isDirectCart,
      setBackdropOpen,
      userCreds.cognitoID
    );
  }

  const navigateToProductPage = (event) => {
    navigate(buildProductUrl(dgiStyleSKU, masterColor));
    event.preventDefault();
  };

  const bottomBorderStyling =
    !lastItem &&
    (isDirectCart ? "1px solid rgba(225, 229, 230, 1)" : "1px solid #959595");

  const caseQtyRequired = checkoutItemEntries.some(
    (entry) => entry.caseQtyRequired
  );
  const caseQty =
    caseQtyRequired && Number(checkoutItemEntries[0]?.caseQtyIncrement);

  return (
    <div
      className={styles.checkoutItemContainer}
      style={{
        borderBottom: bottomBorderStyling,
      }}
    >
      <div className={styles.detailsAndPricingWrapper}>
        <Stack direction="row" spacing={1} sx={{ flex: 1 }}>
          <ProductImage itemImageUrl={itemImageUrl} />
          <div className={styles.checkoutItemDetails}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "6px",
                "&:hover": {
                  "& *": {
                    color: "rgba(0, 110, 165, 1)", // blueblue
                  },
                  cursor: "pointer",
                },
              }}
              onClick={navigateToProductPage}
            >
              <div className={styles.checkoutItemStyle}>
                <span className="text-lg">{itemStyle}</span>
              </div>
              {masterColor && (
                <div className={styles.checkoutItemColor}>
                  <span className="text-fadedGray">Color:</span>
                  <span> {capitalizeWords(masterColor)}</span>
                </div>
              )}
              <div>
                <span>{itemDescription}</span>
              </div>
            </Box>
          </div>
        </Stack>
        <Stack
          direction="column"
          gap="12px"
          sx={{
            width: "60%",
            "@media (min-width: 486px)": {
              minWidth: "486px",
            },
            "@media (max-width: 1200px)": {
              width: "100%",
            },
          }}
        >
          <CaseQuantityNotification
            caseQtyRequired={caseQtyRequired}
            caseQty={caseQty}
          />
          {checkoutItemEntries.map((itemEntry, index) => (
            <CheckoutItemPriceEntry
              key={`${itemEntry.dgi_style_sku}-${itemEntry.quantity}-${itemEntry.warehouse}-${index}`}
              vendorCode={vendorCode}
              itemEntry={itemEntry}
              isDirectCart={isDirectCart}
              handleUpdateItem={handleUpdateItem}
              handleUpdateWarehouse={handleUpdateWarehouse}
              handleRemoveItem={handleRemoveItem}
              setBackdropOpen={setBackdropOpen}
              dispatch={dispatch}
              userCognitoID={userCreds.cognitoID}
            />
          ))}
        </Stack>
      </div>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.modal + 1 }}
        open={backdropOpen}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className={styles.entryTotalPriceWrapper}>
        <span className="text-charcoal">{formatPrice(itemSubtotal)}</span>
      </div>
    </div>
  );
}
