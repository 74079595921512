import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  IconButton,
  Typography,
  Box,
  CircularProgress,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import VendorRankings from "./VendorRankings";
import { useSelector } from 'react-redux';
import { fetchUserSuccess, selectUser } from '../../../features/userSlice';
import { useUpdateDistrPreferencesMutation } from '../../../features/userApi';
import { useDispatch } from 'react-redux';

const PreferencesPopup = ({ open, onClose }) => {
  const user = useSelector(selectUser);
  const [preferences, setPreferences] = React.useState({});
  const [updateDistrPreferences] = useUpdateDistrPreferencesMutation();
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);

  // Initialize preferences from user data
  React.useEffect(() => {
    const userPrefs = user?.printavo?.preferences?.distributors || {};
    const initialPrefs = {};
    
    Object.entries(userPrefs).forEach(([vendorCode, vendorPrefs]) => {
      initialPrefs[vendorCode] = {
        rank: vendorPrefs.rank || 1,
        warehouses: vendorPrefs.warehouses || [],
        enabled: vendorPrefs.enabled !== false, // default to true if not specified
        // is_dropshipping_enabled: vendorPrefs.is_dropshipping_enabled || false,  // Commented out dropshipping
        free_shipping_minimum: Number(vendorPrefs.free_shipping_minimum) || 200
      };
    });

    setPreferences(initialPrefs);
  }, [user]);

  const handlePreferencesUpdate = (vendorCode, updates) => {
    setPreferences(prev => ({
      ...prev,
      [vendorCode]: {
        ...prev[vendorCode],
        ...updates
      }
    }));
  };

  const handleSave = async () => {
    setLoading(true);
    try {
      const updatedUser = await updateDistrPreferences({
        userId: user.UserId,
        userData: {
          distributor_preferences: preferences
        }
      });
      dispatch(fetchUserSuccess({ userObj: updatedUser?.data }));
      onClose();
    } catch (error) {
      console.error('Failed to update preferences:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="lg"
      fullWidth
      PaperProps={{
        sx: {
          height: "90vh",
          maxHeight: "90vh",
          display: 'flex',
          flexDirection: 'column'
        },
      }}
    >
      <DialogContent 
        dividers
        sx={{
          p: 3,
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden',
          height: '100%'
        }}
      >
        <Box sx={{ flexShrink: 0 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 2 }}>
            <CheckBoxIcon sx={{ color: 'rgb(2, 129, 192)' }} />
            <Typography variant="body2" sx={{ color: "text.secondary" }}>
              Enable suppliers for AutoPilot
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 2 }}>
            <LocalShippingIcon sx={{ color: 'rgb(2, 129, 192)' }} />
            <Typography variant="body2" sx={{ color: "text.secondary" }}>
              Set your free shipping minimum with each supplier
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 2 }}>
            <DragIndicatorIcon sx={{ 
              color: 'rgb(2, 129, 192)',
              fontSize: '28px',
              marginLeft: '-2px',
              marginRight: '-2px'
            }} />
            <Typography variant="body2" sx={{ color: "text.secondary" }}>
              Drag and drop to rank your preferred suppliers and warehouses
            </Typography>
          </Box>
        </Box>
        <Box sx={{ 
          flexGrow: 1,
          overflow: 'auto',
          minHeight: 0
        }}>
          <VendorRankings 
            preferences={preferences}
            onPreferencesUpdate={handlePreferencesUpdate}
          />
        </Box>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "space-between", px: 3 }}>
        <Box>
          <Button onClick={onClose} disabled={loading}>Cancel</Button>
        </Box>
        <Box>
          <Button 
            onClick={handleSave} 
            variant="contained" 
            color="primary"
            disabled={loading}
            startIcon={loading ? <CircularProgress size={20} color="inherit" /> : null}
          >
            Save Changes
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default PreferencesPopup;
