import React from 'react';
import { Box, Typography, Container, Button } from '@mui/material';
import SplashPageAnimation from './SplashPageAnimation';

const SplashPage = () => {
  const handleJoinWaitlist = () => {
    window.open('https://l0c0tf2t897.typeform.com/dgi-autopilot', '_blank');
  };

  return (
    <Container maxWidth="md">
      <Box sx={{ 
        display: 'flex', 
        flexDirection: 'column',
        alignItems: 'center',
        pb: 12,
        pt: 4,
        textAlign: 'center',
        gap: 4
      }}>
        <Typography 
          variant="h2" 
          component="h1" 
          sx={{ 
            fontWeight: 'bold',
            mb: 0,
            background: 'linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)',
            backgroundClip: 'text',
            textFillColor: 'transparent',
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
          }}
        >
          Join the AutoPilot Beta
        </Typography>

        <Typography variant="h5" sx={{ color: 'text.secondary' }}>
          Order every invoice with a single click
        </Typography>

        <SplashPageAnimation />

        <Button
          variant="contained"
          size="large"
          onClick={handleJoinWaitlist}
          sx={{
            py: 2,
            px: 6,
            borderRadius: 2,
            fontSize: '1.1rem',
            textTransform: 'none',
            background: 'linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)',
            boxShadow: '0 3px 5px 2px rgba(33, 203, 243, .3)',
            '&:hover': {
              background: 'linear-gradient(45deg, #1976D2 30%, #2196F3 90%)',
            }
          }}
        >
          Join the Waitlist
        </Button>

        <Typography variant="subtitle1" sx={{ color: 'text.secondary', mt: -1 }}>
          $79 per month - Exclusive for Printavo users
        </Typography>
      </Box>
    </Container>
  );
};

export default SplashPage;
