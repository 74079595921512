import React, { useRef, useEffect } from "react";
import { Box, Typography, Button } from "@mui/material";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import { useNavigate } from "react-router-dom";

const AutoplayInterfaceVideo = () => {
  const videoRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.play().catch((error) => {
        console.error("Autoplay was prevented:", error);
      });
    }
  }, []);

  return (
    <Box
      sx={{
        position: "relative",
        width: "100%",
        maxWidth: "100vw",
        height: "100vh",
        overflow: "hidden",
      }}
    >
      <video
        ref={videoRef}
        width="100%"
        height="100%"
        loop
        muted
        playsInline
        autoPlay
        style={{ objectFit: "cover" }}
      >
        <source
          src={"https://d34c0c1in94n75.cloudfront.net/new_landing_v2.mov"}
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: "2rem",
          backgroundColor: "rgba(0,0,0,0.5)",
        }}
      >
        <Box
          sx={{
            marginBottom: 2,
            "@media (max-width: 800px)": {
              maxWidth: "90%",
              marginBottom: "4rem",
            },
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            gap: 2,
          }}
        >
          <Typography
            variant="h3"
            sx={{
              color: "white",
              textAlign: "center",
            }}
          >
            All of your wholesale suppliers.
          </Typography>

          <Typography
            variant="h3"
            className="text-center text-[rgb(111,195,220)]"
          >
            One simple site.
          </Typography>
        </Box>
        <Box
          sx={{ display: "flex", gap: 6, flexWrap: "wrap" }}
          className="flex-col sm:flex-row"
        >
          <Button
            variant="contained"
            color="primary"
            className="normal-case text-2xl rounded-soft text-lighterblueblue bg-grayscaleWhite hover:bg-babyBlue"
            sx={{
              border: "3px solid #DDF1F7",
            }}
            onClick={() => navigate("/product-demo")}
          >
            How It Works
          </Button>
          <Button
            variant="contained"
            color="primary"
            className="bg-hardOrange flex justify-center items-center gap-[8px] hover:bg-softOrange"
            sx={{
              border: "1px solid #FF7F11",
            }}
            onClick={() => navigate("/auth")}
          >
            <Typography className="normal-case text-2xl rounded-soft text-grayscaleWhite">
              Start Now
            </Typography>
            <DoubleArrowIcon className="text-3xl text-grayscaleWhite" />
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default AutoplayInterfaceVideo;
