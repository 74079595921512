import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  purchaseOrders: [],
  isLoading: false,
  error: null,
};

export const purchaseOrdersSlice = createSlice({
  name: "purchaseOrders",
  initialState,
  reducers: {
    fetchPurchaseOrdersStart: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    fetchPurchaseOrderVendorUpdate: (state, action) => {
      const newPO = action.payload;
      state.purchaseOrders = state.purchaseOrders.map(po => 
        po.distributor === newPO.distributor ? newPO : po
      );
      state.isLoading = false;
      state.error = null;
    },
    fetchPurchaseOrdersSuccess: (state, action) => {
      state.purchaseOrders = action.payload;
      state.isLoading = false;
      state.error = null;
    },
    fetchPurchaseOrdersError: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const {
  fetchPurchaseOrdersStart,
  fetchPurchaseOrderVendorUpdate,
  fetchPurchaseOrdersSuccess,
  fetchPurchaseOrdersError,
} = purchaseOrdersSlice.actions;

// Selectors
export const selectPurchaseOrders = (state) => state.purchaseOrders.purchaseOrders;
export const selectPurchaseOrdersLoading = (state) => state.purchaseOrders.isLoading;
export const selectPurchaseOrdersError = (state) => state.purchaseOrders.error;

export default purchaseOrdersSlice.reducer; 