import React, { useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Box,
  Checkbox,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import InvoiceLineItems from "./InvoiceLineItems.js";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { useSelector } from "react-redux";
import { selectConfirmedOverrides } from "../../features/invoicesSlice";

const STATUS_CONFIG = {
  processing: {
    text: "Processing...",
    styles: {
      backgroundColor: "#DDF1F7",
      color: "rgba(0, 110, 165, 1)",
    },
  },
  addedToCart: {
    text: "Added to Carts",
    styles: {
      backgroundColor: "#e6ffe6",
      color: "#008000",
    },
  },
  error: {
    text: "", // Will be populated by reason
    styles: {
      backgroundColor: "#ffe6e6",
      color: "#ff3333",
    },
  },
  default: {
    text: "",
    styles: {
      backgroundColor: "transparent",
      color: "transparent",
    },
  },
};

const getStatus = (isProcessing, isAddedToCart, reason, dgiCatalogMatch) => {
  if (isProcessing) return STATUS_CONFIG.processing;
  if (isAddedToCart) return STATUS_CONFIG.addedToCart;
  if (reason || !dgiCatalogMatch) {
    return {
      ...STATUS_CONFIG.error,
      text: reason || "Error Loading",
    };
  }
  return STATUS_CONFIG.default;
};

const HighlightedText = ({ text, highlight }) => {
  if (!highlight) return text;

  const parts = text.split(new RegExp(`(${highlight})`, "gi"));
  return parts.map((part, index) =>
    part.toLowerCase() === highlight.toLowerCase() ? (
      <span key={index} style={{ backgroundColor: "#fff3b0" }}>
        {part}
      </span>
    ) : (
      part
    )
  );
};

const InvoiceSummary = ({
  name = "",
  totalQuantity,
  total,
  productionDueDate,
  dgiCatalogMatch,
  reason,
  searchQuery,
  isProcessing,
  isAddedToCart,
}) => {
  const status = getStatus(
    isProcessing,
    isAddedToCart,
    reason,
    dgiCatalogMatch
  );
  const formattedDate = productionDueDate
    ? new Date(productionDueDate).toLocaleDateString("en-US", {
        month: "2-digit",
        day: "2-digit",
      })
    : "N/A";

  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: total
          ? "20% 22% 15% 16% 15% 10%"
          : "20% 30% 17% 20% 10%",
        alignItems: "center",
        width: "100%",
        gap: 2,
        paddingRight: "8px",
      }}
    >
      <Typography sx={{ paddingLeft: !total ? "15px" : "0px" }}>
        <HighlightedText
          text={(name || "").replace("\n", " ")}
          highlight={searchQuery}
        />
      </Typography>
      <Box
        sx={{
          ...status.styles,
          py: 0.5,
          px: 1.5,
          borderRadius: "4px",
          width: "100%",
          textAlign: "center",
        }}
      >
        <Typography
          sx={{
            fontSize: "0.875rem",
            color: status.styles.color,
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {status.text}
        </Typography>
      </Box>
      <Typography
        sx={{
          textAlign: total ? "left" : "right",
          fontSize: "0.875rem",
          paddingLeft: "10px",
        }}
      >
        Items: {totalQuantity || "0"}
      </Typography>
      {total && (
        <Typography sx={{ textAlign: "left", fontSize: "0.875rem" }}>
          Total: {total}
        </Typography>
      )}
      <Typography sx={{ textAlign: "right", fontSize: "0.875rem" }}>
        Due: {formattedDate}
      </Typography>
    </Box>
  );
};

const InvoiceAccordion = ({
  invoice,
  selectedInvoices,
  setSelectedInvoices,
  processingInvoices,
  searchQuery,
  manualReviewRequired,
}) => {
  const [dgiCatalogMatch, setDgiCatalogMatch] = useState(true);
  const [expanded, setExpanded] = useState(manualReviewRequired);
  const confirmedInvoiceOverrides = useSelector((state) =>
    selectConfirmedOverrides(state, invoice.invoice_id)
  );
  // Find if this invoice is selected
  const selectedInvoice = selectedInvoices?.find(
    (i) => i.invoice_id === invoice.invoice_id
  );
  const isInvoiceChecked =
    selectedInvoice?.add_all_lines || selectedInvoice?.line_item_ids.length > 0;

  // Find if this invoice is being processed
  const isProcessing = processingInvoices?.some(
    (i) => i.invoice_id === invoice.invoice_id
  );

  const handleInvoiceCheckboxChange = () => {
    setSelectedInvoices((prev) => {
      const existingIndex = prev.findIndex(
        (i) => i.invoice_id === invoice.invoice_id
      );

      if (existingIndex >= 0) {
        // Remove invoice if it exists
        return prev.filter((i) => i.invoice_id !== invoice.invoice_id);
      } else {
        // Add invoice with add_all_lines true
        return [
          ...prev,
          {
            invoice_id: invoice.invoice_id,
            line_item_ids: [],
            add_all_lines: true,
          },
        ];
      }
    });
  };

  const getInvoiceReason = () => {
    // Check all DGI items for errors
    for (const item of invoice.dgi_items) {
      if (item.not_found) {
        // Skip if we have an override for this item
        if (
          item?.line_item_id &&
          confirmedInvoiceOverrides?.[item.line_item_id]
        ) {
          continue;
        }

        // Return first error reason found
        if (item?.reason) {
          return item.reason;
        }
      }
    }

    // No unhandled errors found
    return null;
  };

  return (
    <Accordion
      expanded={expanded}
      onChange={(_, isExpanded) => setExpanded(isExpanded)}
      key={invoice.invoice_number + " " + invoice.production_due_date}
      sx={{
        mt: 0,
        borderRadius: 0,
        boxShadow: "none",
        borderTop: "1px solid rgba(0, 0, 0, 0.12)",
        "&::before": {
          display: "none",
        },
        "& .MuiPaper-root": {
          borderRadius: 0,
          boxShadow: "none",
        },
        "&:last-of-type": {
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
        },
        "&:first-of-type": {
          borderTop: "none",
        },
        "&.Mui-expanded": {
          margin: "0",
        },
        "& .MuiAccordionSummary-root": {
          backgroundColor: manualReviewRequired 
            ? "#ffe6e6"
            : isInvoiceChecked
            ? "rgba(2, 129, 192, 0.04)"
            : "transparent",
          "&:hover": {
            backgroundColor: manualReviewRequired
              ? "#ffd9d9"
              : "#F5F5F5",
          },
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          width: "100%",
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`panel-${
            invoice.invoice_number + " " + invoice.date
          }-content`}
          id={`panel-${invoice.invoice_number + " " + invoice.date}-header`}
          sx={{
            position: "sticky",
            top: "0",
            zIndex: "5",
            flex: 1,
            paddingLeft: "0px",
            borderRadius: "0px",
            paddingRight: "8px",
            "& .MuiAccordionSummary-content": {
              margin: "0",
              flex: 1,
              display: "flex",
              alignItems: "center",
            },
            width: "90%",
            height: "48px",
            "&.Mui-expanded": {
              minHeight: "48px",
              height: "48px",
            },
            "&:hover": {
              backgroundColor: "#F5F5F5",
            },
          }}
        >
          {!manualReviewRequired && (
            <Box
              sx={{
                width: "10%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Checkbox
                checked={isInvoiceChecked}
                onChange={handleInvoiceCheckboxChange}
                onClick={(e) => e.stopPropagation()}
                sx={{
                  borderRadius: "4px",
                  "&.Mui-checked": {
                    color: isProcessing ? "#9e9e9e" : undefined,
                  },
                }}
                icon={<CheckBoxOutlineBlankIcon />}
                checkedIcon={<CheckBoxIcon />}
                disabled={isProcessing}
              />
            </Box>
          )}
          <InvoiceSummary
            name={invoice.invoice_number}
            totalQuantity={invoice.total_quantity}
            total={invoice.total}
            productionDueDate={invoice.production_due_date}
            dgiCatalogMatch={dgiCatalogMatch}
            reason={getInvoiceReason()}
            searchQuery={searchQuery}
            isProcessing={isProcessing}
            isAddedToCart={invoice.added_to_cart}
          />
        </AccordionSummary>
      </Box>
      <AccordionDetails
        sx={{
          boxShadow: "none",
          padding: 0,
          borderTop: "1px dashed rgba(0, 0, 0, 0.12)",
          borderRadius: 0,
          "& .MuiPaper-root": {
            borderRadius: 0,
          },
        }}
      >
        <InvoiceLineItems
          dgiLineItems={invoice.dgi_items}
          invoiceId={invoice.invoice_id}
          setDgiCatalogMatch={setDgiCatalogMatch}
          selectedInvoices={selectedInvoices}
          setSelectedInvoices={setSelectedInvoices}
          manualReviewRequired={manualReviewRequired}
          processingInvoices={processingInvoices}
        />
      </AccordionDetails>
    </Accordion>
  );
};

const InvoiceAccordionStack = ({
  invoices,
  selectedInvoices,
  setSelectedInvoices,
  processingInvoices,
  searchQuery,
  manualReviewRequired,
}) => {
  return (
    <Box
      sx={{
        border: "1px solid lightgray",
        borderLeft: "1px solid rgb(2, 129, 192)",
        marginTop: "0 !important",
      }}
    >
      {invoices?.map((invoice) => (
        <InvoiceAccordion
          key={invoice?.invoice_number}
          invoice={invoice}
          selectedInvoices={selectedInvoices}
          setSelectedInvoices={setSelectedInvoices}
          processingInvoices={processingInvoices}
          searchQuery={searchQuery}
          manualReviewRequired={manualReviewRequired}
        />
      ))}
    </Box>
  );
};

const LoadedInvoices = ({
  invoices,
  selectedInvoices,
  setSelectedInvoices,
  processingInvoices,
  searchQuery,
  manualReviewRequired = false,
}) => {
  return (
    <InvoiceAccordionStack
      invoices={invoices}
      selectedInvoices={selectedInvoices}
      setSelectedInvoices={setSelectedInvoices}
      processingInvoices={processingInvoices}
      searchQuery={searchQuery}
      manualReviewRequired={manualReviewRequired}
    />
  );
};

export default LoadedInvoices;
