import React, { useState, useRef, useEffect } from "react";
import { useDataContext } from "App";
import { usePostHog } from "posthog-js/react";
import { TextField, IconButton } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import styles from "header/styles.module.scss";
import ManualSearchResults from "./ManualSearchResults";
import useSearchConfig from "useSearchConfig";

const ManualSearchBar = ({
  onStyleSelect,
  setAvailableColors,
  item,
  selectedStyle,
  setSelectedColor,
  initialSelectedColor,
  onColorSelect,
  defaultValue,
}) => {
  const [inputValue, setInputValue] = useState(defaultValue || "");
  const [autoCompleteSuggestions, setAutoCompleteSuggestions] = useState([]);
  const containerRef = useRef(null);
  const { performSearch } = useSearchConfig();

  const autoRecs = item?.alternatives?.map((alt) => {
    return {
      dgi_style_sku: alt.dgi_style_sku,
      master_style: alt.style_number,
      master_short_description: alt.master_short_description,
      colors: alt.colors?.map(color => color.name)
    };
  }) || [];

  const submitQuery = async (value = inputValue) => {
    const searchResp = await performSearch(value);
    const hits = searchResp?.results?.[0]?.hits;
    setAutoCompleteSuggestions(hits?.map(hit => ({
      ...hit,
      colors: hit.colors?.map(c => c.name)
    })));
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      const hit = autoCompleteSuggestions?.[0];
      if (hit) {
        onStyleSelect(hit);
      }
    }
  };

  const showSuggestions =
    (inputValue === "" && autoRecs.length > 0) ||
    (inputValue !== "" && autoCompleteSuggestions.length > 0);

  // Override SearchResults props to only show our sections
  const searchResultsProps = {
    showSuggestions,
    menuWidth: containerRef.current?.offsetWidth,
    filteredNewRecommendations: autoRecs,
    autoCompleteSuggestions,
    submitQuery,
    inputValue,
    selectedStyle,
    onStyleSelect,
    setAvailableColors,
    setSelectedColor
  };

  useEffect(() => {
    // When colors become available, pre-select the initial color if it exists
    if (setAvailableColors.length > 0 && initialSelectedColor) {
      const colorToSelect = setAvailableColors.find(color => color === initialSelectedColor);
      if (colorToSelect) {
        onColorSelect(colorToSelect);
        setSelectedColor(colorToSelect); // Also need to set the local state
      }
    }
  }, [setAvailableColors, initialSelectedColor, onColorSelect]);

  // Add this effect to handle initial search and selection
  useEffect(() => {
    if (defaultValue) {
      submitQuery(defaultValue).then(() => {
        // After getting suggestions, find and select the matching style
        const matchingStyle = autoCompleteSuggestions?.find(
          hit => hit.master_style === selectedStyle?.master_style
        );
        if (matchingStyle) {
          onStyleSelect(matchingStyle);
        }
      });
    }
  }, [defaultValue]); // Run when defaultValue changes

  return (
    <div
      ref={containerRef}
      className={`flex flex-col w-full bg-grayscaleWhite 
          rounded-tr-softer rounded-tl-softer
          ${!showSuggestions && "rounded-br-softer rounded-bl-softer"}
          h-full overflow-hidden`}
    >
      <div
        className={`w-full h-12 bg-grayscaleWhite
        rounded-tr-softer rounded-tl-softer
        ${
          !showSuggestions &&
          "rounded-br-softer rounded-bl-softer rounded-tr-softer rounded-tl-softer"
        }`}
      >
        <div
          className={`flex flex-row items-end gap-[0.5rem] 
              w-full h-full p-[4px]
              ${
                showSuggestions
                  ? "rounded-tr-softer rounded-tl-softer"
                  : "rounded-br-softer rounded-bl-softer rounded-tr-softer rounded-tl-softer"
              }`}
        >
          <div className="flex-grow h-full w-full relative">
            <TextField
              fullWidth
              autoComplete="off"
              value={inputValue}
              onChange={(e) => {
                setInputValue(e.target.value);
                submitQuery(e.target.value);
                if (e.nativeEvent.inputType === "deleteContentBackward") {
                  setAvailableColors([]);
                  onStyleSelect(null);
                  setSelectedColor(null);
                }
              }}
              placeholder="Search items by style number or brand..."
              variant="standard"
              className="pb-[1px]"
              InputProps={{
                disableUnderline: !showSuggestions,
                className:
                  "custom-search-input bg-grayscaleWhite w-full min-h-full rounded-l-full rounded-r-full flex justify-start gap-[0.75rem] pt-[2px]",
                classes: {
                  underline: "custom-underline",
                },
                startAdornment: (
                  <IconButton
                    onClick={() => submitQuery()}
                    className="hover:bg-transparent pt-[6px]"
                    aria-label="search"
                  >
                    <SearchIcon className="fill-charcoal text-[22px]" />
                  </IconButton>
                ),
                inputProps: {
                  className: `${styles.customPlaceholder} ${
                    inputValue.length > 0 && "pt-[6px]"
                  }`,
                },
              }}
              onKeyDown={handleKeyDown}
            />
          </div>
        </div>
      </div>
      {showSuggestions && <ManualSearchResults {...searchResultsProps} />}
    </div>
  );
};

export default ManualSearchBar;
