import React from "react";
import { Tooltip } from "@mui/material";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import { highlightText } from "./orderSearchUtilities";

// Helper function to identify USPS tracking numbers
const isUSPSTracking = (trackingNumber) => {
  if (!trackingNumber) return false;
  
  // USPS 20-digit numbers starting with specific prefixes
  if (trackingNumber.length === 20) {
    const prefix = trackingNumber.substring(0, 2);
    return ['92', '93', '94', '95', '96'].includes(prefix);
  }
  
  // Traditional 22-digit USPS
  return trackingNumber.length === 22;
};

// Helper function to identify FedEx tracking numbers
const isFedExTracking = (trackingNumber) => {
  if (!trackingNumber || isNaN(trackingNumber)) return false;
  
  // FedEx tracking numbers are numeric and can be 12, 14, 15, or 20 digits
  const validLengths = [12, 14, 15, 20];
  return validLengths.includes(trackingNumber.length);
};

export const renderTrackingNumbers = (trackingNumbers, searchInput) => {
  if (!trackingNumbers || trackingNumbers?.length === 0) {
    return [];
  }
  return trackingNumbers
    .map((trackingNumber, index) => {
      const highlightedTrackingNumber = highlightText(
        trackingNumber,
        searchInput
      );
      const handleClick = (e) => e.stopPropagation();

      if (trackingNumber.startsWith("1Z")) {
        // UPS tracking number
        return (
          <a
            key={index}
            href={`https://www.ups.com/track?track=yes&trackNums=${trackingNumber}&loc=en_US&requester=ST/trackdetails`}
            target="_blank"
            rel="noopener noreferrer"
            style={{
              textDecoration: "underline",
              color: "blue",
              fontSize: "inherit",
            }}
            onClick={handleClick}
          >
            {highlightedTrackingNumber}
          </a>
        );
      } else if (isUSPSTracking(trackingNumber)) {
        // USPS tracking number
        return (
          <a
            key={index}
            href={`https://tools.usps.com/go/TrackConfirmAction?qtc_tLabels1=${trackingNumber}`}
            target="_blank"
            rel="noopener noreferrer"
            style={{
              textDecoration: "underline",
              color: "blue",
              fontSize: "inherit",
            }}
            onClick={handleClick}
          >
            {highlightedTrackingNumber}
          </a>
        );
      } else if (isFedExTracking(trackingNumber)) {
        // FedEx tracking number
        return (
          <a
            key={index}
            href={`https://www.fedex.com/fedextrack/?trknbr=${trackingNumber}`}
            target="_blank"
            rel="noopener noreferrer"
            style={{
              textDecoration: "underline",
              color: "blue",
              fontSize: "inherit",
            }}
            onClick={handleClick}
          >
            {highlightedTrackingNumber}
          </a>
        );
      } else {
        return (
          <span key={index} className="text-sm">
            {highlightedTrackingNumber}
          </span>
        );
      }
    })
    .reduce((prev, curr) => [prev, ", ", curr]);
};

export const renderTrackingNumberIcon = (trackingNumbers) => {
  const handleClick = (e) => e.stopPropagation();

  return (
    <Tooltip
      componentsProps={{
        tooltip: {
          sx: {
            background: "#f9f9f9",
            opacity: "0.6",
            fontSize: "14px",
            color: "#111",
            marginTop: "6px !important",
            boxShadow:
              "0 15px 35px rgba(50, 50, 93, 0.1), 0 5px 15px rgba(0, 0, 0, 0.07)",
            border: "1px solid darkgray",
          },
        },
        arrow: {
          sx: {
            color: "#f9f9f9",
            borderBottom: "none",
            "::before": {
              border: "1px solid darkgray",
            },
          },
        },
      }}
      title={trackingNumbers.map((trackingNumber, index) => (
        <div key={index} style={{ textAlign: "center" }}>
          <a
            href={
              trackingNumber.startsWith("1Z")
                ? `https://www.ups.com/track?trackNums=${trackingNumber}`
                : isUSPSTracking(trackingNumber)
                ? `https://tools.usps.com/go/TrackConfirmAction?qtc_tLabels1=${trackingNumber}`
                : `https://www.fedex.com/fedextrack/?trknbr=${trackingNumber}`
            }
            target="_blank"
            rel="noopener noreferrer"
            style={{
              textDecoration: "underline",
              color: "blue",
            }}
            onClick={handleClick}
          >
            {trackingNumber}
          </a>
        </div>
      ))}
      arrow
    >
      <LocalShippingIcon sx={{ color: "rgba(0, 110, 165, 1)" }} />
    </Tooltip>
  );
};
