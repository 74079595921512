export const BETA_TESTERS = new Set([
  "04b8a418-d071-705f-1b80-24fe025317d8", // Grant
  "38b08723-d7f7-49d0-855e-d84e86b81d28", // Ian
  "64d824e8-9071-70dd-6e2a-c9122e3c6007", // Daniel
  "4498d498-5011-70f2-860a-c0c9396b6947", // Kerry
  "8418b468-9071-70b0-379d-378503ec8a5f", // Jay
  "445824b8-a031-7016-def8-6fd7f2b3cd5a", // Big Frog Raleigh-North
  "249804d8-c061-70f3-0dad-a59aadae85cb", // UME Custom
  "24386468-40d1-70bb-03ae-35b5cef62c0f", // Carolina Shirt Company
  "ae195e56-9d38-401e-8942-dcccf11a38e4", // Mesh Print Studio
]);

export const DISABLE_CHECKOUT = new Set([
  "9e72c39a-a923-42bf-bcc8-b1f231a54a27", // Demo Account
  "bef15d05-965b-4d1a-89a0-83dc626e7c08", // Kevin Baumgart
  "af0e9544-e6a8-4af0-8e1b-1bdbbc5fdc4e", // Test account (Ryan SLC)
]);

export const INVOICE_TESTERS = new Set([
  "d5ffe8f4-6d30-459f-b880-38b3a7751ba4", // LogoDaddy
  "db009728-6940-45c1-bee3-d3f96a09f6a7", // Tiny Little Monster
  "64d824e8-9071-70dd-6e2a-c9122e3c6007", // Daniel
  "04b8a418-d071-705f-1b80-24fe025317d8", // Grant
  "38b08723-d7f7-49d0-855e-d84e86b81d28", // Ian
  "c5b327ff-84d1-4ef7-87db-82a2fdd44b91", // STL Shirt Co
  "e6cab38e-b05e-44a5-b50c-3135d7610558", // Apparel Lab
  "97bb4b39-50a7-405b-8689-830ecffd031b", // Mitchell Tees
  "13cf6657-4c58-4ebd-8834-cdecf0feb830", // Rip Print
])
