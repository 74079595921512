import { createSlice, createAction } from "@reduxjs/toolkit";

// Create the action first
export const setConfirmedOverride = createAction('invoices/setConfirmedOverride');

const initialState = {
  invoices: [],
  isLoading: false,
  error: null,
  invoicesInProgress: [],
  confirmedOverrides: {}
};

export const invoicesSlice = createSlice({
  name: "invoices",
  initialState,
  reducers: {
    fetchInvoicesStart: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    fetchInvoicesSuccess: (state, action) => {
      state.invoices = action.payload;
      state.isLoading = false;
      state.error = null;
    },
    fetchInvoicesError: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    updateInvoicesAddedToCart: (state) => {
      state.invoices = state.invoices.map((invoice) => {
        if (state.invoicesInProgress.includes(invoice.invoice_id)) {
          return { ...invoice, added_to_cart: true };
        }
        return invoice;
      });
    },
    updateInvoicesInProgress: (state, action) => {
      state.invoicesInProgress = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setConfirmedOverride, (state, action) => {
      const { invoiceId, lineItemId, style, color, quantity } = action.payload;
      if (!state.confirmedOverrides[invoiceId]) {
        state.confirmedOverrides[invoiceId] = {};
      }
      state.confirmedOverrides[invoiceId][lineItemId] = {
        confirmedStyle: style,
        confirmedColor: color,
        quantity
      };
    });
  }
});

export const {
  fetchInvoicesStart,
  fetchInvoicesSuccess,
  fetchInvoicesError,
  updateInvoicesAddedToCart,
  updateInvoicesInProgress,
} = invoicesSlice.actions;

// Selectors
export const selectInvoices = (state) => state.invoices.invoices;
export const selectInvoicesLoading = (state) => state.invoices.isLoading;
export const selectInvoicesError = (state) => state.invoices.error;
export const selectConfirmedOverrides = (state, invoiceId) => state.invoices.confirmedOverrides[invoiceId];

export default invoicesSlice.reducer;
