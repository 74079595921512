import React, { useState, useMemo } from "react";
import { selectCarts } from "features/cartsSlice";
import { useSelector } from "react-redux";
import VendorFilterBar from "./VendorFilters";
import VendorWarehouses from "./VendorWarehouses";
import {
  FormControl,
  Tooltip,
  Select,
  MenuItem,
  Box,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";

const getVendorData = (
  vendorCode,
  validItemInventory,
  itemStyleSku,
  masterColor
) => {
  if (!vendorCode)
    return {
      vendorInvData: [],
      availableSizes: new Set(),
    };

  const dataKey = itemStyleSku + vendorCode + masterColor;
  const vendorData = validItemInventory[dataKey]?.data;

  if (!vendorData?.inventory_data) {
    return {
      vendorInvData: [],
      availableSizes: new Set(),
    };
  }

  const availableSizes = new Set();
  vendorData.inventory_data.forEach((whse) => {
    whse[1]?.quantities.forEach((q) => {
      if (q.sizeLabel) availableSizes.add(q.sizeLabel);
    });
  });

  return {
    vendorInvData: vendorData.inventory_data,
    availableSizes,
  };
};

const getVendorPricingData = (
  vendorCode,
  selectedSize,
  validItemInventory,
  itemStyleSku,
  masterColor
) => {
  if (!vendorCode || !selectedSize) return null;

  const dataKey = itemStyleSku + vendorCode + masterColor;
  const vendorData = validItemInventory[dataKey]?.data;

  return vendorData?.sized_pricing_data?.find(
    ([size]) => size === selectedSize
  )?.[1];
};
const StyledFormControl = styled(FormControl)(({ theme }) => ({
  minWidth: 80,
}));

const SizeSelector = ({
  sizes,
  selectedSize,
  onSizeChange,
  disabled,
  noSizeMatch,
}) => {
  if (sizes.size === 0) return null;

  return (
    <Box
      sx={{
        display: "inline-flex",
        alignItems: "center",
        gap: "12px",
        "& .MuiTypography-root": {
          lineHeight: 1,
        },
        marginLeft: "225px",
        marginBottom: "-20px",
        position: "relative", // Added for tooltip positioning
      }}
    >
      <Typography
        component="label"
        sx={{
          fontWeight: 500,
          display: "flex",
          alignItems: "center",
          marginTop: "5px",
        }}
      >
        Selected Size:
      </Typography>
      <Tooltip
        open={noSizeMatch}
        placement="right"
        arrow
        PopperProps={{
          sx: {
            "& .MuiTooltip-tooltip": {
              width: "220px",
              backgroundColor: "#FFA552",
              fontSize: "0.875rem",
              padding: "8px 12px",
            },
            "& .MuiTooltip-arrow": {
              color: "#FFA552",
            },
          },
        }}
        title={`Original size is not available from this vendor`}
      >
        <StyledFormControl size="small">
          <Select
            value={selectedSize || ""}
            onChange={(e) => onSizeChange(e.target.value)}
            disabled={disabled}
            sx={{
              "& .MuiSelect-select": {
                padding: "6px 14px",
                paddingBottom: "2px",
                display: "flex",
                alignItems: "center",
              },
              "& .MuiSelect-icon": {
                top: "calc(50% - 10px)",
              },
            }}
          >
            {Array.from(sizes).map((size) => (
              <MenuItem key={size} value={size}>
                {size}
              </MenuItem>
            ))}
          </Select>
        </StyledFormControl>
      </Tooltip>
    </Box>
  );
};

function VendorOptions({
  validItemInventory,
  validVendorCodes,
  item,
  onSwitchCart,
}) {
  const validCarts = useSelector((state) =>
    selectCarts(state, validVendorCodes)
  );

  const sortedValidCarts = useMemo(() => {
    if (!validCarts) return [];

    return Object.entries(validCarts)
      .sort(([, cartA], [, cartB]) => {
        if (!cartA?.totalPrice && !cartB?.totalPrice) return 0;
        if (!cartA?.totalPrice) return 1;
        if (!cartB?.totalPrice) return -1;
        return cartB.totalPrice - cartA.totalPrice;
      })
      .map(([code, cart]) => ({
        code,
        totalPrice: cart?.totalPrice,
      }));
  }, [validCarts]);

  const [selectedVendor, setSelectedVendor] = useState(
    sortedValidCarts[0]?.code || ""
  );
  const [selectedWarehouse, setSelectedWarehouse] = useState("");

  const vendorData = useMemo(() => {
    return getVendorData(
      selectedVendor,
      validItemInventory,
      item.dgi_style_sku,
      item.master_color
    );
  }, [
    selectedVendor,
    validItemInventory,
    item.dgi_style_sku,
    item.master_color,
  ]);
  const [noSizeMatch, setNoSizeMatch] = useState(false);

  const initialSize = useMemo(() => {
    if (vendorData.availableSizes.size === 0) return "";

    const matchingSize = Array.from(vendorData.availableSizes).find(
      (size) => size.toLowerCase() === item.size.toLowerCase()
    );

    setNoSizeMatch(!matchingSize);

    return matchingSize || Array.from(vendorData.availableSizes)[0];
  }, [vendorData.availableSizes, item.size]);

  const [selectedSize, setSelectedSize] = useState(initialSize);

  // Reset size when vendor changes or when available sizes change
  const derivedSelectedSize = useMemo(() => {
    if (vendorData.availableSizes.size === 0) return "";
    if (!Array.from(vendorData.availableSizes).includes(selectedSize)) {
      return initialSize;
    }
    return selectedSize;
  }, [selectedSize, vendorData.availableSizes, initialSize]);

  // Update the selected size when the derived value changes
  if (derivedSelectedSize !== selectedSize) {
    setSelectedSize(derivedSelectedSize);
  }

  const selectedPrice = useMemo(() => {
    return getVendorPricingData(
      selectedVendor,
      derivedSelectedSize,
      validItemInventory,
      item.dgi_style_sku,
      item.master_color
    );
  }, [
    selectedVendor,
    derivedSelectedSize,
    validItemInventory,
    item.dgi_style_sku,
    item.master_color,
  ]);

  const getVendorUnitPrice = (vendorCode) => {
    // we return the cheapest price since we can't rely on size matching across vendors
    const dataKey = item.dgi_style_sku + vendorCode + item.master_color;
    return validItemInventory[dataKey]?.data?.sized_pricing_data?.[0]?.[1];
  };

  const handleVendorSelect = (vendorCode) => {
    if (vendorCode !== selectedVendor) {
      setSelectedVendor(vendorCode);
      setSelectedWarehouse("");
    }
  };

  const handleSwitchCart = () => {
    if (!selectedWarehouse || !selectedPrice) return;

    const selectedWarehouseData = vendorData.vendorInvData.find(
      ([name]) => name === selectedWarehouse
    );

    if (selectedWarehouseData) {
      onSwitchCart(selectedPrice, selectedVendor, {
        ...selectedWarehouseData[1],
        name: selectedWarehouseData[0],
        selectedSize: derivedSelectedSize,
      });
    }
  };

  return (
    <div className="flex flex-col p-4">
      <div className="w-full flex justify-start">
        <div className="px-[24px] mb-[20px]">
          <span className="text-sm">{`${item.style}, ${item.master_color}`}</span>
          <div className="text-wolfGrey flex items-center gap-[16px]">
            <div className="text-base">
              <span className="text-typeGrey text-base">Size:</span> {item.size}
            </div>
            <div className="text-base">
              <span className="text-typeGrey text-base">Quantity:</span>{" "}
              {item.quantity}
            </div>
          </div>
        </div>
        <SizeSelector
          sizes={vendorData.availableSizes}
          selectedSize={derivedSelectedSize}
          onSizeChange={setSelectedSize}
          disabled={!selectedVendor}
          noSizeMatch={noSizeMatch}
        />
      </div>

      <div className="flex space-x-12 w-full">
        <VendorFilterBar
          sortedValidCarts={sortedValidCarts}
          selectedVendorCode={selectedVendor}
          selectedSize={derivedSelectedSize}
          getVendorUnitPrice={getVendorUnitPrice}
          onVendorSelect={handleVendorSelect}
        />

        <VendorWarehouses
          warehouseData={vendorData.vendorInvData}
          selectedSize={derivedSelectedSize}
          selectedVendorCode={selectedVendor}
          item={item}
          selectedPrice={selectedPrice}
          selectedWarehouse={selectedWarehouse}
          onWarehouseSelect={setSelectedWarehouse}
          onSwitchCart={handleSwitchCart}
        />
      </div>
    </div>
  );
}

export default VendorOptions;
