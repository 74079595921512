import React from "react";
import { Box, Typography, Paper, Avatar } from "@mui/material";
import testimonialsData from "../../constants/testimonials";
import Carousel from "react-material-ui-carousel";
import { useMediaQuery } from "@mui/material";

const Testimonials = () => {
  const showShortText = useMediaQuery("(max-width:430px)");

  return (
    <Box
      sx={{
        width: "100%",
        minHeight: "550px",
        px: 3,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: '0px !important',
        "@media (max-width: 500px)": {
          height: "auto", // Let the Box height adjust automatically on smaller screens
        },
      }}
      className="py-[0rem] mt-[0px]"
    >
      <Carousel
        animation="slide"
        navButtonsAlwaysVisible={!useMediaQuery("(max-width:600px)")}
        autoPlay={true}
        interval={6000}
        indicators={false}
        duration={1000}
        stopAutoPlayOnHover={false}
        sx={{
          width: "100%",
          height: "100%",
          maxWidth: "1100px",
          "& .MuiButtonBase-root": {
            backgroundColor: "#676F78 !important",
            "&:hover": {
              backgroundColor: "#F8F8F8",
            },
            "& svg": {
              fill: "#CEF4FF",
            },
          },
        }}
      >
        {testimonialsData.map((testimonial, index) => (
          <Paper
            key={index}
            elevation={3}
            sx={{
              height: "100%",
              minHeight: "550px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: "28px",
              background: "transparent",
              boxShadow: "none",
              "@media (max-width: 640px)": {
                height: "auto", // Allow height to adjust on small screens
              },
            }}
          >
            <Box
              sx={{
                width: "55%",
                margin: "0 auto",
                padding: "5%",
                textAlign: "center",
                mb: 3,
                "@media (max-width: 700px)": {
                  width: "95%",
                  padding: "2%",
                },
              }}
            >
              <Typography
                variant="body1"
                sx={{
                  fontStyle: "italic",
                  fontSize: "1.5rem", // Default font size
                  "@media (max-width: 500px)": {
                    fontSize: "1.2rem", // Smaller font size for screens below 550px
                  },
                }}
                className="text-wolfGrey"
              >
                "
                {showShortText
                  ? testimonial.shortText || testimonial.text
                  : testimonial.text}
                "
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: 2,
                px: 2,
              }}
            >
              <Avatar
                src={testimonial.shop_logo}
                alt={`${testimonial.shopName} logo`}
                className="w-[80px] h-[80px] sm:w-[140px] sm:h-[140px]"
              />
              <Box sx={{ textAlign: "start" }}>
                <Typography
                  variant="subtitle1"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "1.125rem", // Default font size
                    "@media (max-width: 500px)": {
                      fontSize: "1rem", // Smaller font size for screens below 500px
                    },
                  }}
                  className="text-wolfGrey"
                >
                  {testimonial.author}
                </Typography>

                <Typography
                  variant="body2"
                  sx={{
                    fontSize: "1.125rem", // Default font size
                    "@media (max-width: 500px)": {
                      fontSize: "1rem", // Smaller font size for screens below 500px
                    },
                  }}
                  className="text-wolfGrey"
                >
                  {testimonial.position}, {testimonial.shopName}
                </Typography>

                <Typography
                  variant="body2"
                  sx={{
                    fontStyle: "italic",
                    fontSize: "1.125rem", // Default font size
                    "@media (max-width: 500px)": {
                      fontSize: "1rem", // Smaller font size for screens below 500px
                    },
                  }}
                  className="text-wolfGrey"
                >
                  {testimonial.location}
                </Typography>
              </Box>
            </Box>
          </Paper>
        ))}
      </Carousel>
    </Box>
  );
};

export default Testimonials;
