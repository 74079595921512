import React from 'react';
import { Grid, Typography } from '@mui/material';
import VendorCard from './VendorCard';

const VendorGrid = ({ vendors = [] }) => {
  // Create a shallow copy of the vendors array and sort it
  const sortedVendors = [...vendors].sort((a, b) => {
    // First, handle coming soon vendors
    if (a.comingSoon && !b.comingSoon) return 1;
    if (!a.comingSoon && b.comingSoon) return -1;
    
    // Then sort by review count for non-coming-soon vendors
    if (!a.comingSoon && !b.comingSoon) {
      const aReviews = a.reviews ? Object.keys(a.reviews).length : 0;
      const bReviews = b.reviews ? Object.keys(b.reviews).length : 0;
      return bReviews - aReviews; // Sort in descending order
    }
    
    return 0;
  });

  return (
    <Grid container spacing={3}>
      {sortedVendors.length > 0 ? (
        sortedVendors.map((vendor) => (
          <Grid item xs={12} sm={6} lg={4} xl={3} key={vendor.id}>
            <VendorCard vendor={vendor} />
          </Grid>
        ))
      ) : (
        <Typography variant="body1" align="center" sx={{ width: '100%', mt: 4 }}>
          No vendors available.
        </Typography>
      )}
    </Grid>
  );
};

export default VendorGrid;
