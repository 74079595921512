import React from "react";
import { Box, Typography } from "@mui/material";
import ManualSearchBar from "./ManualSearchBar";

const ManualReviewStyleSelector = ({
  item,
  onStyleSelect,
  selectedStyle,
  setAvailableColors,
  setSelectedColor,
  initialSearchValue,
}) => {
  return (
    <Box sx={{ 
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    }}>
      <Typography variant="h6" sx={{ mb: 2 }}>
        Select New Style
      </Typography>
      <Box sx={{ 
        flexGrow: 1,
        overflow: 'hidden' // This ensures the SearchBar's overflow is contained
      }}>
        <ManualSearchBar
          onStyleSelect={onStyleSelect}
          selectedStyle={selectedStyle}
          setAvailableColors={setAvailableColors}
          setSelectedColor={setSelectedColor}
          item={item}
          defaultValue={initialSearchValue}
        />
      </Box>
    </Box>
  );
};

export default ManualReviewStyleSelector;
