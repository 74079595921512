import { baseApi, BASE_API_URL } from './baseApi';

export const userApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    updateUserById: builder.mutation({
      query: ({ userId, userData }) => ({
        baseUrl: BASE_API_URL,
        url: `/users/${userId}`,
        method: "PUT",
        body: JSON.stringify(userData),
      }),
    }),

    updateDistrPreferences: builder.mutation({
      query: ({ userId, userData }) => ({
        baseUrl: BASE_API_URL,
        url: `/users/${userId}/distributor-preferences`,
        method: "PUT",
        body: JSON.stringify(userData),
      }),
    }),

    getUserById: builder.query({
      query: (userId) => ({
        baseUrl: BASE_API_URL,
        url: `/users/${userId}`,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useUpdateUserByIdMutation,
  useUpdateDistrPreferencesMutation,
  useGetUserByIdQuery,
} = userApi; 