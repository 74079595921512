import React, { useState, useEffect } from 'react';
import DGIWatermark from "components/DGIWatermark";

export const LineItemImage = ({ src, productUrl }) => {
  const [imgSrc, setImgSrc] = useState(src);

  useEffect(() => {
    setImgSrc(src);
  }, [src]);

  const onSrcError = () => {
    setImgSrc(null);
  };

  if (!imgSrc) {
    return <DGIWatermark />;
  }

  const image = (
    <img
      src={imgSrc}
      onError={onSrcError}
      alt={null}
      style={{
        display: "block",
        height: "75px",
        maxHeight: "100%",
        margin: 0,
        maxWidth: "100%",
        width: "auto",
        cursor: "pointer",
      }}
    />
  );

  return productUrl ? (
    <a
      href={productUrl}
      target="_blank"
      rel="noopener noreferrer"
      style={{ textDecoration: "none" }}
    >
      {image}
    </a>
  ) : (
    image
  );
}; 