import React, { useState } from "react";
import {
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  Typography,
  Backdrop,
  CircularProgress,
  IconButton,
} from "@mui/material";
import { getURLColor, constructImageUrl } from "utilities/imageUrlUtils";
import { buildProductUrl } from "utilities/navigation";
import SwitchCartInterface from "pages/cart/switch_cart/SwitchCartInterface";
import DGIWatermark from "components/DGIWatermark";
import CancelIcon from "@mui/icons-material/Cancel";
import AlertSnackbar from "alerts/AlertSnackbar";
import { handleRemoveItem } from "utilities/cartPageOperations";
import { useDispatch } from "react-redux";
import { useUserStateContext } from "App";

const LineItemImage = ({ src, productUrl }) => {
  const [imgSrc, setImgSrc] = useState(src);

  const onSrcError = () => {
    setImgSrc(null);
  };

  if (!imgSrc) {
    return <DGIWatermark />;
  }

  const image = (
    <img
      src={src}
      onError={onSrcError}
      alt=""
      style={{
        display: "block",
        height: "75px",
        maxHeight: "100%",
        margin: 0,
        maxWidth: "100%",
        width: "auto",
        cursor: "pointer",
      }}
    />
  );

  return productUrl ? (
    <a
      href={productUrl}
      target="_blank"
      rel="noopener noreferrer"
      style={{ textDecoration: "none" }}
    >
      {image}
    </a>
  ) : (
    image
  );
};

const getImgUrl = (item) => {
  if (item?.product_image_url) {
    return item.product_image_url;
  }
  if (item?.images?.length > 0) {
    return item.images[0];
  }
  const urlColor = getURLColor({ name: item?.master_color || item?.color });
  return constructImageUrl(item?.dgi_style_sku, urlColor, 0);
};

const POInvoiceLineItems = ({ invoiceItems, vendorCode }) => {
  const [successAlertOpen, setSuccessAlertOpen] = useState(false);
  const [errorAlertOpen, setErrorAlertOpen] = useState(false);
  const [backdropOpen, setBackdropOpen] = useState(false);
  const dispatch = useDispatch();
  const { userCreds } = useUserStateContext();

  const handleCloseSuccessAlert = (event, reason) => {
    if (reason === "clickaway") return;
    setSuccessAlertOpen(false);
  };

  const handleCloseErrorAlert = (event, reason) => {
    if (reason === "clickaway") return;
    setErrorAlertOpen(false);
  };

  const onRemoveSuccess = () => {
    setSuccessAlertOpen(true);
  };

  const onRemoveError = () => {
    setErrorAlertOpen(true);
  };

  // Group items by style and color
  const groupItems = (items) => {
    const groups = {};
    items.forEach((item) => {
      const key = `${item.dgi_style_sku}_${item.master_color}`;
      if (!groups[key]) {
        const imageUrl = getImgUrl(item);

        groups[key] = {
          style: item.style,
          dgi_style_sku: item.dgi_style_sku,
          color: item.master_color,
          imageUrl,
          sizes: [],
        };
      }
      groups[key].sizes.push({
        ...item,
        master_color: item.master_color,
      });
    });
    return Object.values(groups);
  };

  const groupedItems = groupItems(invoiceItems);

  return (
    <>
      <TableContainer component={Paper}>
        <Table>
          <TableBody>
            {groupedItems.map((group, groupIndex) => {
              const productUrl = buildProductUrl(
                group.dgi_style_sku,
                group.color
              );

              return (
                <TableRow
                  key={groupIndex}
                  sx={{
                    borderBottom:
                      groupIndex === groupedItems.length - 1
                        ? "none"
                        : "1px dashed rgba(0, 0, 0, 0.12)",
                  }}
                >
                  <TableCell
                    sx={{
                      verticalAlign: "top",
                      width: "100px",
                      padding: "8px",
                      borderBottom: "none",
                    }}
                  >
                    <LineItemImage
                      src={group.imageUrl}
                      productUrl={productUrl}
                    />
                  </TableCell>
                  <TableCell
                    sx={{
                      verticalAlign: "top",
                      width: "18%",
                      padding: "8px",
                      borderBottom: "none",
                    }}
                  >
                    <a
                      href={productUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{
                        textDecoration: "none",
                        color: "inherit",
                        cursor: "pointer",
                      }}
                    >
                      <Typography
                        component="span"
                        sx={{
                          textDecoration: "none",
                          fontSize: "0.875rem",
                          "&:hover": {
                            textDecoration: "underline",
                          },
                        }}
                      >
                        {group.style}
                      </Typography>
                    </a>
                  </TableCell>
                  <TableCell
                    sx={{
                      verticalAlign: "top",
                      width: "18%",
                      padding: "8px",
                      borderBottom: "none",
                    }}
                  >
                    {group.color}
                  </TableCell>
                  <TableCell
                    colSpan={2}
                    sx={{
                      verticalAlign: "top",
                      width: "50%",
                      padding: "2px 8px",
                      borderBottom: "none",
                    }}
                  >
                    {group.sizes.map((sizeInfo, sizeIndex) => (
                      <div
                        key={sizeIndex}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "8px",
                          justifyContent: "space-between",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "8px",
                            width: "12%",
                          }}
                        >
                          {sizeInfo.size}:
                        </div>
                        <div style={{ width: "4%", textAlign: "right" }}>
                          {sizeInfo.quantity}
                        </div>
                        <div style={{ width: "15%", textAlign: "right" }}>
                          $
                          {(typeof sizeInfo.unitPrice === "number"
                            ? sizeInfo.unitPrice
                            : Number(sizeInfo.unitPrice)
                          )?.toFixed(2)}
                        </div>
                        <div
                          style={{
                            width: "28%",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {sizeInfo.warehouse}
                        </div>
                        <IconButton
                          onClick={() =>
                            handleRemoveItem(
                              vendorCode,
                              sizeInfo,
                              onRemoveSuccess,
                              onRemoveError,
                              setBackdropOpen,
                              dispatch,
                              userCreds.cognitoID
                            )
                          }
                          className="fill-charcoal"
                          size="small"
                        >
                          <CancelIcon />
                        </IconButton>
                        <SwitchCartInterface
                          item={sizeInfo}
                          sourceVendorCode={vendorCode}
                          isIconButton={true}
                        />
                      </div>
                    ))}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      <AlertSnackbar
        open={successAlertOpen}
        severity="success"
        text="Successfully Removed Item"
        handleClose={handleCloseSuccessAlert}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      />
      <AlertSnackbar
        open={errorAlertOpen}
        severity="error"
        text="Unable to remove item from cart"
        handleClose={handleCloseErrorAlert}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.modal + 1 }}
        open={backdropOpen}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default POInvoiceLineItems;
