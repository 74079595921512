import React, { useState, useEffect } from "react";
import { Button, CircularProgress, Dialog, Backdrop } from "@mui/material";
import { Shuffle } from "lucide-react";
import { useDispatch, useSelector } from "react-redux";
import { createSelectVendorProducts } from "features/productsSlice";
import { useUserStateContext } from "App";
import { LOADING_STATES } from "features/productsSlice";
import {
  fetchCartSuccess,
  tagCartItemsWithInvoiceIDs,
} from "features/cartsSlice";
import { switchCart } from "utilities/switchCart";
import { fetchPrice } from "features/useSocketPayloadSender";
import { useWebsocketContext } from "authsignin/AuthedPage";
import { selectStyle } from "features/styleSlice";
import { processProductData } from "pages/product/ProductPage";
import VendorOptionsWrapper from "./VendorOptions";
import lilProductClient from "../../../axios/lilProductClient";
import endpoints from "../../../axios/endpoints";
import { fetchStyleSuccess } from "features/styleSlice";
import useMediaQuery from "@mui/material/useMediaQuery";
import { selectLineItems, updateAutoAddLineItems } from "features/autoAddSlice";
import { isMatchingLineItem } from "features/cartsSlice";
import { usePostHog } from "posthog-js/react";

function SwitchCartDialog({
  open,
  handleClose,
  item,
  sourceVendorCode,
  styleData,
}) {
  const { userCreds } = useUserStateContext();
  const dispatch = useDispatch();
  const { sendJsonMessage } = useWebsocketContext();
  const autoAddLineItems = useSelector(selectLineItems);
  const posthog = usePostHog();

  const connectedVendors = useSelector(
    (state) => Object.keys(state?.user?.user?.Distributors) || []
  );

  const selectItemInventory = createSelectVendorProducts(
    item?.dgi_style_sku,
    connectedVendors,
    item?.master_color
  );
  const itemInventory = useSelector(selectItemInventory);

  useEffect(() => {
    const allValuesUndefined = Object.values(itemInventory || {}).every(
      (value) => value === undefined
    );
    const dataStillLoading = Object.values(itemInventory).some(
      (value) => value?.loading === LOADING_STATES.LOADING_STATUS
    );

    if (allValuesUndefined || !dataStillLoading) {
      fetchPrice(
        dispatch,
        userCreds?.cognitoID,
        connectedVendors,
        styleData,
        item?.master_color,
        sendJsonMessage
      );
    }
  }, [item.dgi_style_sku]);

  const validItemInventory = Object.fromEntries(
    Object.entries(itemInventory ?? {}).filter(([_, productData]) => {
      const isLoading = productData?.loading === LOADING_STATES.LOADING_STATUS;
      const isAvailable =
        productData?.loading === LOADING_STATES.SUCCESS_STATUS &&
        productData?.data &&
        Object.keys(productData.data).length > 0 &&
        Boolean(productData.data.inventory_data);
      return (
        productData?.loading !== LOADING_STATES.FAILURE_STATUS &&
        (isLoading || isAvailable)
      );
    })
  );

  const validVendorCodes = Object.keys(validItemInventory)
    .map((key) =>
      key.slice(item.dgi_style_sku.length, -item.master_color.length)
    )
    .filter((code, index, self) => self.indexOf(code) === index);

  const getSSProductUrlShort = (vendorCode) => {
    if (vendorCode === "ss") {
      const dataKey = item.dgi_style_sku + "ss" + item.master_color;
      const productUrl = validItemInventory[dataKey]?.data?.url;
      return productUrl ? new URL(productUrl).pathname : null;
    }
    return null;
  };

  const handleSwitchCart = async (
    selectedItemPrice,
    selectedVendorCode,
    destWarehouse
  ) => {
    const destWarehouseCode = destWarehouse.warehouseCode;
    const selectedSize = destWarehouse.selectedSize;

    const destItem = {
      ...destWarehouse.quantities.find((q) => q.sizeLabel === selectedSize),
      quantity: item.quantity,
      warehouseCode: destWarehouseCode,
      warehouse: destWarehouse.name,
    };

    const ssProductUrl = getSSProductUrlShort(selectedVendorCode);
    if (ssProductUrl) {
      destItem.ss_product_url_short = ssProductUrl;
    }

    const { source_result, dest_result } = await switchCart(
      sourceVendorCode,
      [item],
      item.warehouseCode,
      selectedVendorCode,
      [destItem],
      destWarehouseCode,
      selectedItemPrice,
      userCreds?.cognitoID
    );

    // dispatch(
    //   fetchCartSuccess({
    //     vendorCode: sourceVendorCode,
    //     cartData: source_result.cartData,
    //   })
    // );

    const findMatchingLineItem = (cartItem, lineItems) => {
      const index = lineItems.findIndex((lineItem) =>
        isMatchingLineItem(cartItem, lineItem, sourceVendorCode)
      );
      return index >= 0 ? { item: lineItems[index], index } : null;
    };

    const match = findMatchingLineItem(item, autoAddLineItems);
    const copyAutoAddLineItems = [...autoAddLineItems];
    if (match) {
      const { price, stockNum, ...rest } = match.item;
      copyAutoAddLineItems[match.index] = {
        ...rest,
        warehouseCode: destWarehouseCode,
        warehouse: destItem.warehouse,
        vendorCode: selectedVendorCode,
        distributor: selectedVendorCode,
        style_data_key: rest.style_data_key.replace(
          new RegExp(sourceVendorCode, "i"),
          selectedVendorCode
        ),
      };
    }

    dispatch(
      fetchCartSuccess({
        vendorCode: sourceVendorCode,
        cartData: {
          ...source_result?.cartData,
          items: tagCartItemsWithInvoiceIDs(
            sourceVendorCode,
            source_result?.cartData?.items,
            copyAutoAddLineItems
          ),
        },
      })
    );

    if (selectedVendorCode !== sourceVendorCode) {
      // dispatch(
      //   fetchCartSuccess({
      //     vendorCode: selectedVendorCode,
      //     cartData: dest_result.cartData,
      //   })
      // );
      dispatch(
        fetchCartSuccess({
          vendorCode: selectedVendorCode,
          cartData: {
            ...dest_result?.cartData,
            items: tagCartItemsWithInvoiceIDs(
              selectedVendorCode,
              dest_result?.cartData?.items,
              copyAutoAddLineItems
            ),
          },
        })
      );
      dispatch(updateAutoAddLineItems(copyAutoAddLineItems));
    }

    posthog?.capture('switch_cart', {
      sourceVendorCode,
      sourceItemNumber: item.itemNumber,
      sourceItemQuantity: item.quantity,
      sourceWarehouseCode: item.warehouseCode,
      sourceUnitPrice: item.unitPrice,
      destinationVendorCode: selectedVendorCode,
      destinationItemNumber: destItem.itemNumber,
      destinationItemQuantity: destItem.quantity,
      destinationWarehouseCode: destWarehouseCode,
      destinationUnitPrice: selectedItemPrice
    });

    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <VendorOptionsWrapper
        validVendorCodes={validVendorCodes}
        validItemInventory={validItemInventory}
        item={item}
        onSwitchCart={handleSwitchCart}
      />
    </Dialog>
  );
}

function SwitchCartWrapper({ open, handleClose, item, sourceVendorCode }) {
  const { userCreds } = useUserStateContext();
  const dispatch = useDispatch();
  const styleData = useSelector((state) =>
    selectStyle(state, item?.dgi_style_sku)
  );

  useEffect(() => {
    const fetchStyleData = async () => {
      if (!styleData) {
        const headers = { "User-Identifier": userCreds?.cognitoID };
        const productResp = await lilProductClient.get(
          endpoints.getProductInfo(item?.dgi_style_sku),
          { headers }
        );
        dispatch(
          fetchStyleSuccess({
            styleObj: processProductData(productResp?.data),
          })
        );
      }
    };
    fetchStyleData();
  }, [item.dgi_style_sku, styleData]);

  if (!styleData) {
    return (
      <Backdrop
        sx={{
          backgroundColor: "rgba(88, 88, 88, 0.2)",
          zIndex: (theme) => theme.zIndex.modal,
        }}
        open={!styleData}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }

  return (
    <SwitchCartDialog
      open={open}
      handleClose={handleClose}
      item={item}
      sourceVendorCode={sourceVendorCode}
      styleData={styleData}
    />
  );
}

function SwitchCartInterface({ item, sourceVendorCode, isIconButton = false }) {
  const [switchCartDialogOpen, setSwitchCartDialogOpen] = useState(false);
  const isSmallerScreen = useMediaQuery("(max-width:1400px)");
  const iconOnly = isSmallerScreen || isIconButton;

  return (
    <div className="flex items-center max-w-fit">
      <Button
        className={`normal-case text-typeGrey ${
          iconOnly ? "w-[30px] min-w-[30px] p-[6px]" : "w-fit p-[8px]"
        } rounded-[5px] flex items-center`}
        disableRipple
        sx={{
          transition: "all 0.2s ease",
          "&:hover": {
            backgroundColor: "transparent",
            padding: iconOnly ? "8px" : "10px",
          },
          "&:hover span, svg": {
            color: "rgba(0, 110, 165)",
          },
        }}
        onClick={() => setSwitchCartDialogOpen(true)}
      >
        {iconOnly ? (
          <Shuffle size={15} className="text-typeGrey" />
        ) : (
          <span className="flex items-center text-typeGrey text-almostSM">
            Switch Cart <Shuffle size={15} className="ml-[6px]" />
          </span>
        )}
      </Button>

      {switchCartDialogOpen && (
        <SwitchCartWrapper
          open={switchCartDialogOpen}
          handleClose={() => setSwitchCartDialogOpen(false)}
          item={item}
          sourceVendorCode={sourceVendorCode}
        />
      )}
    </div>
  );
}

export default SwitchCartInterface;
