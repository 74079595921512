import { configureStore } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import products from "features/productsSlice";
import carts from "features/cartsSlice";
import user from "features/userSlice";
import styles from "features/styleSlice";
import invoices from "features/invoicesSlice";
import purchaseOrders from "features/purchaseOrdersSlice";
import { baseApi } from "features/baseApi";
import webSocketMiddleware from "features/webSocketMiddleware";
import dgiApiWebSocketMiddleware from "features/dgiApiWebSocketMiddleware";
import autoAddReducer from '../features/autoAddSlice';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['user'] // only persist user slice
};

const persistedUserReducer = persistReducer(persistConfig, user);

export const store = configureStore({
  reducer: {
    products: products,
    carts: carts,
    user: persistedUserReducer,
    styles: styles,
    [baseApi.reducerPath]: baseApi.reducer,
    invoices: invoices,
    autoAdd: autoAddReducer,
    purchaseOrders: purchaseOrders,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ['persist/PERSIST', 'persist/REHYDRATE'],
      },
    })
      .concat(webSocketMiddleware)
      .concat(dgiApiWebSocketMiddleware)
      .concat(baseApi.middleware),
});

export default store;
