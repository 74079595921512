import React, { useState, useEffect } from 'react';
import {
  DataGridPro,
} from '@mui/x-data-grid-pro';
import { Box } from '@mui/material';

const updateRowPosition = (initialIndex, newIndex, rows) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      const rowsClone = [...rows];
      const row = rowsClone.splice(initialIndex, 1)[0];
      rowsClone.splice(newIndex, 0, row);
      resolve(rowsClone);
    }, 100);
  });
};

const WarehouseRankings = ({ vendorId, warehouses, onWarehousesReorder }) => {
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const initialRows = warehouses.map((warehouse, index) => ({
      id: index + 1,
      warehouse,
      rank: index + 1
    }));
    setRows(initialRows);
  }, [warehouses]);

  const columns = [
    {
      field: 'rank',
      headerName: 'Rank',
      width: 70,
      sortable: false,
      renderCell: (params) => (
        <Box sx={{ 
          width: '100%', 
          display: 'flex', 
          alignItems: 'center',
          height: '100%',
          justifyContent: 'center',
        }}>
          {params.value}
        </Box>
      ),
    },
    {
      field: 'warehouse',
      headerName: 'Warehouse',
      flex: 1,
      sortable: false,
    },
  ];

  const handleRowOrderChange = async (params) => {
    setLoading(true);
    const newRows = await updateRowPosition(
      params.oldIndex,
      params.targetIndex,
      rows,
    );

    const updatedRows = newRows.map((row, index) => ({
      ...row,
      rank: index + 1
    }));

    // Create new warehouses array based on the new order
    const reorderedWarehouses = updatedRows.map(row => row.warehouse);
    
    // Call the callback with reordered warehouses
    if (onWarehousesReorder) {
      onWarehousesReorder(reorderedWarehouses);
    }

    setRows(updatedRows);
    setLoading(false);
  };

  return (
    <div style={{ width: '100%' }}>
      <DataGridPro
        rows={rows}
        columns={columns}
        loading={loading}
        rowReordering
        onRowOrderChange={handleRowOrderChange}
        disableColumnMenu
        hideFooter
        disableSelectionOnClick
        getRowHeight={() => 36}
        autoHeight
        sx={{
          border: 'none',
          '&.MuiDataGrid-root .MuiDataGrid-row .MuiDataGrid-rowReorderCell': {
            alignItems: 'center !important',
            paddingTop: '0 !important',
          },
          '& .MuiDataGrid-cell': {
            padding: 0,
            border: 'none',
            height: '36px',
          },
          '& .MuiDataGrid-columnHeaders': {
            display: 'none',
          },
          '& .MuiDataGrid-virtualScroller': {
            marginTop: '0!important',
            overflow: 'hidden !important',
            '& .MuiDataGrid-virtualScrollerContent': {
              overflow: 'hidden !important',
            },
          },
          '& .MuiDataGrid-scrollbar': {
            display: 'none !important',
          },
          '& .MuiDataGrid-cell:focus': {
            outline: 'none',
          },
          '& .MuiDataGrid-cell:focus-within': {
            outline: 'none',
          },
        }}
      />
    </div>
  );
};

export default WarehouseRankings;
