import { createCartEntry } from "./createCartEntry";
import endpoints from "../axios/endpoints";
import ssClient from "../axios/ssClient.js";
import directClient from "../axios/directClient";
import { fetchCartSuccess } from "features/cartsSlice";

export const updateItems = async (
  code,
  body,
  onSuccess,
  onError,
  dispatch,
  isDirectCart,
  setBackdropOpen,
  userCognitoID
) => {
  const headers = {
    "User-Identifier": userCognitoID,
  };
  const params = {
    distributor: isDirectCart ? "direct" : code,
  };
  var client;
  if (code === "ss") {
    client = ssClient;
  } else {
    client = directClient;
  }
  return await client
    .post(endpoints.cart(), body, { params, headers })
    .then(
      (resp) => {
        // and displaying something
        onSuccess();
        dispatch(
          fetchCartSuccess({
            vendorCode: code,
            cartData: resp?.data?.cartData,
          })
        );
        setBackdropOpen(false);
        return true;
      }
      // Handle successful update by updating displayCartData
    )
    .catch((error) => {
      onError();
      setBackdropOpen(false);
      console.error(`Error updating items: ${error.message}`);
      return null;
    });
};

export async function handleRemoveItem(
  code,
  item,
  onSuccess,
  onError,
  setBackdropOpen,
  dispatch,
  userCognitoID,
  isDirectCart = false
) {
  setBackdropOpen(true);
  const itemWhse =
    code === "ab" ||
    code === "cm" ||
    code === "acc" ||
    code === "sm" ||
    code === "sta"
      ? item.warehouseCode.toLowerCase()
      : item.warehouse;
  const updateItem = isDirectCart
    ? {
        ...item,
        vendorCode: code,
        warehouse: itemWhse,
        updatingEntry: true,
        quantity: 0,
      }
    : createCartEntry(code, itemWhse, "0", null, item);
  const body = {
    items: Array(updateItem),
  };
  if (code === "ss") {
    body["product_url_short"] = item.ss_product_url_short;
  } else if (code === "asc") {
    body["asc_product_id"] = item.asc_product_id;
  }
  return await updateItems(
    code,
    body,
    onSuccess,
    onError,
    dispatch,
    isDirectCart,
    setBackdropOpen,
    userCognitoID
  );
}
