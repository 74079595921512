import React, { useState, useEffect } from 'react';
import {
  DataGridPro,
} from '@mui/x-data-grid-pro';
import VendorDropdown from './VendorDropdown';
import { Box } from '@mui/material';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../features/userSlice';

const updateRowPosition = (initialIndex, newIndex, rows) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      const rowsClone = [...rows];
      const row = rowsClone.splice(initialIndex, 1)[0];
      rowsClone.splice(newIndex, 0, row);
      resolve(rowsClone);
    }, 100);
  });
};

const VendorRankings = ({ preferences, onPreferencesUpdate }) => {
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const user = useSelector(selectUser);

  useEffect(() => {
    const userPrefs = user?.printavo?.preferences?.distributors || {};
    
    const initialRows = Object.entries(userPrefs).map(([vendorCode, vendorPrefs]) => ({
      id: vendorCode,
      vendor: vendorCode,
      vendorName: vendorCode.toUpperCase(),
      rank: preferences[vendorCode]?.rank || vendorPrefs.rank || 15,
      freeShippingMinimum: preferences[vendorCode]?.free_shipping_minimum || vendorPrefs.free_shipping_minimum || 200,
      warehouses: preferences[vendorCode]?.warehouses || vendorPrefs.warehouses || [],
      enabled: preferences[vendorCode]?.enabled ?? true,
      // is_dropshipping_enabled: preferences[vendorCode]?.is_dropshipping_enabled || false, // Commented out dropshipping
    }));

    initialRows.sort((a, b) => a.rank - b.rank);
    
    const updatedRows = initialRows.map((row, index) => ({
      ...row,
      rank: index + 1
    }));

    setRows(updatedRows);
  }, [user, preferences]);

  const columns = [
    {
      field: 'rank',
      headerName: 'Rank',
      width: 70,
      sortable: false,
      renderCell: (params) => (
        <Box sx={{ 
          width: '100%', 
          display: 'flex', 
          alignItems: 'flex-start',
          height: '100%',
          justifyContent: 'center',
          paddingTop: '10px',
          fontSize: '18px',
          fontWeight: 500
        }}>
          {params.value}
        </Box>
      ),
    },
    {
      field: 'vendor',
      headerName: 'Supplier',
      flex: 1,
      sortable: false,
      renderCell: (params) => (
        <VendorDropdown 
          row={{
            ...params.row,
            vendor: params.row.vendor,
            logoUrl: `https://d34c0c1in94n75.cloudfront.net/${params.row.vendor}_logo.png`
          }}
          preferences={preferences || {}}
          onPreferencesUpdate={onPreferencesUpdate}
        />
      ),
    },
  ];

  const handleRowOrderChange = async (params) => {
    setLoading(true);
    const newRows = await updateRowPosition(
      params.oldIndex,
      params.targetIndex,
      rows,
    );

    const updatedRows = newRows.map((row, index) => {
      const newRank = index + 1;
      // Update preferences with new rank
      onPreferencesUpdate(row.id, { rank: newRank });
      return {
        ...row,
        rank: newRank
      };
    });

    setRows(updatedRows);
    setLoading(false);
  };

  return (
    <div style={{ width: '100%', height: '100%' }}>
      <DataGridPro
        rows={rows}
        columns={columns}
        loading={loading}
        rowReordering
        onRowOrderChange={handleRowOrderChange}
        disableColumnMenu
        hideFooter
        disableSelectionOnClick
        getRowHeight={() => 'auto'}
        sx={{
          height: '100%',
          '& .MuiDataGrid-main': {
            overflow: 'hidden'
          },
          '& .MuiDataGrid-cell': {
            padding: 0,
            border: 'none',
          },
          '& .MuiDataGrid-columnHeaders': {
            borderBottom: '1px solid rgba(224, 224, 224, 1)',
            '& .MuiDataGrid-columnHeader': {
              padding: '0 16px',
              height: '48px',
              '&:focus, &:focus-within': {
                outline: 'none',
              },
            },
          },
          '& .MuiDataGrid-virtualScroller': {
            marginTop: '0!important',
          },
          '& .MuiDataGrid-row': {
            position: 'relative',
            '& .MuiDataGrid-cell': {
              position: 'relative',
              zIndex: 1,
            },
            '&:first-of-type': {
              borderTop: 'none',
            },
            '&:last-of-type': {
              borderBottom: 'none',
            },
            '&:hover': {
              backgroundColor: '#F5F5F5',
              cursor: 'pointer',
            },
            '&:has(.expanded-row)': {
              backgroundColor: 'transparent !important',
              '&::before': {
                content: '""',
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                height: '48px',
                backgroundColor: '#F5F5F5',
                borderBottom: '1px dashed rgba(0, 0, 0, 0.12)',
                pointerEvents: 'none',
                zIndex: 0,
              }
            },
            '& .MuiDataGrid-rowReorderCell': {
              alignItems: 'flex-start',
              paddingTop: '12px',
            },
            '&.Mui-expanded .MuiDataGrid-rowReorderCell': {
              alignItems: 'flex-start',
              paddingTop: '13px',
            },
          },
          '& .MuiDataGrid-row.Mui-selected': {
            backgroundColor: 'transparent',
          },
          '& .MuiDataGrid-row.Mui-selected:hover': {
            backgroundColor: '#F5F5F5',
          },
          '& .MuiDataGrid-cell:focus': {
            outline: 'none',
          },
          '& .MuiDataGrid-cell:focus-within': {
            outline: 'none',
          },
        }}
      />
    </div>
  );
};

export default VendorRankings;
