import { createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
  name: "user",
  initialState: { user: {}},
  reducers: {
    fetchUserSuccess(state, action) {
      const { userObj } = action.payload;
      state.user = userObj;
    },
    logout(state) {
      state.user = {};
    },
  },
});

export default userSlice.reducer;

export const selectUser = (state) => state.user.user;

export const {
  fetchUserSuccess,
  logout,
} = userSlice.actions;
