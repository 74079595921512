import React from "react";
import { Paper, Typography } from "@mui/material";

const SearchResultOption = ({
  option,
  submitQuery,
  isSelected,
  setAvailableColors,
  onStyleSelect,
  searchValue,
}) => {
  const optionName = `${option.master_style} - ${option.master_short_description}`;
  
  // Create a function to highlight matching text
  const highlightMatch = (text, searchValue) => {
    if (!searchValue) return <span className="font-normal">{text}</span>;
    
    const parts = text.split(new RegExp(`(${searchValue})`, 'gi'));
    return parts.map((part, index) => 
      part.toLowerCase() === searchValue.toLowerCase() ? 
        <span key={index} className="font-bold">{part}</span> : 
        <span key={index} className="font-normal">{part}</span>
    );
  };

  return (
    <div
      className={`
        flex items-center space-x-4 px-[12px] py-[8px]
        w-full text-almostSM
        ${isSelected ? "bg-dgiWhite" : "hover:bg-lightestGrey"}
        cursor-pointer text-wolfGrey
      `}
      onClick={() => {
        setAvailableColors(option?.colors);
        onStyleSelect(option);
      }}
    >
      <div className="flex-1">
        <Typography>
          {highlightMatch(optionName, searchValue)}
        </Typography>
      </div>
      <input
        type="radio"
        checked={isSelected}
        onChange={() => {
          setAvailableColors(option?.colors);
          onStyleSelect(option);
        }}
        className="accent-[rgb(2_129_192)] w-4 h-4"
      />
    </div>
  );
};

const ManualSearchResults = ({
  showSuggestions,
  menuWidth,
  filteredNewRecommendations,
  autoCompleteSuggestions,
  submitQuery,
  inputValue,
  selectedStyle,
  onStyleSelect,
  setAvailableColors,
}) => {
  const wrappedSubmitQuery = (option) => {
    submitQuery(option);
  };

  const isOptionSelected = (option) => {
    return selectedStyle?.dgi_style_sku === option?.dgi_style_sku;
  };

  if (!showSuggestions) return null;

  return (
    <Paper
      elevation={0}
      style={{
        width: menuWidth,
        height: "calc(100% - 48px)",
        overflowY: "auto",
        borderBottomLeftRadius: "25px",
        borderBottomRightRadius: "25px",
        borderTopLeftRadius: "0",
        borderTopRightRadius: "0",
      }}
    >
      <div className="h-full overflow-y-auto rounded-bl-[25px] rounded-br-[25px]">
        {(inputValue === ""
          ? filteredNewRecommendations
          : autoCompleteSuggestions.slice(0, 7)
        ).map((option, index) => (
          <SearchResultOption
            key={`${inputValue === "" ? "auto" : "search"}-${index}`}
            option={option}
            submitQuery={wrappedSubmitQuery}
            isSelected={isOptionSelected(option)}
            setAvailableColors={setAvailableColors}
            onStyleSelect={onStyleSelect}
            searchValue={inputValue}
          />
        ))}
      </div>
    </Paper>
  );
};

export default ManualSearchResults;
